import moment from 'moment'
import BaseObject from './base_object'

export const LOADING = -1

export const OFFLINE = 0
export const STANDBY = 1
export const ACTIVE = 2

export const TASK_NOT_STARTED = 'none'
export const TASK_PAUSED = 'paused'
export const TASK_ACTIVE = 'active'
export const TASK_COMPLETED = 'done'
export const TASK_CANCELLED = 'cancelled'

const emptyUnit = {
  id: null,
  name: '',
  owner_id: null,
  company_code: null,
  resource_type_id: null,
  status: STANDBY,
  is_active: false,
  is_offline: false,
  division_id: null,
  description: '',
  status_description: '',
  latitude: null,
  longitude: null,
  is_visible: true,
  location_updated: null,
  latest_location: null,
  linked_hub_ids: [],
  operator: null,
  can_edit: true,
}

export class UnitNumber extends BaseObject {
  name: string
  companyName: string | null
  ownerId: string | null
  organizationId: string
  resourceTypeId: number | null
  resourceTypeName: string | null
  isActive: boolean
  isOffline: boolean
  status: number
  divisionId: number | null
  divisionName: string | null
  description: string
  statusDescription: string
  latitude: number | null
  longitude: number | null
  latestLocation: Record<string, never> | null
  isVisible: boolean
  canEdit: boolean
  locationUpdated: moment.Moment | null
  operator: {
    occurredAt: moment.Moment
    userSid: string
    onDuty: boolean
  } | null

  constructor(json, resourceTypeId: number | null = null) {
    if (!json) {
      json = emptyUnit
    }
    super(json)

    this.name = json.name
    this.companyName = json.company_name
    this.ownerId = json.owner_id
    this.organizationId = json.root_organization_id
    this.resourceTypeId = resourceTypeId || json.resource_type_id
    this.resourceTypeName = resourceTypeId || json.resource_type_name
    this.isActive = json.is_active
    this.isOffline = json.is_offline
    this.divisionId = json.division_id
    this.divisionName = json.division_name
    this.description = json.description
    this.statusDescription = json.status_description
    this.latitude = json.latitude
    this.longitude = json.longitude
    this.latestLocation = json.latest_location
    this.isVisible = json.is_visible
    this.canEdit = json.can_edit
    this.locationUpdated =
      json.location_updated && moment.utc(json.location_updated).local()
    this.operator = json.operator
      ? {
          userSid: json.operator.user_sid,
          onDuty: json.operator.on_duty,
          occurredAt: moment.utc(json.operator.occurred_at),
        }
      : null
    if (this.isOffline) {
      this.status = OFFLINE
    } else if (this.isActive) {
      this.status = ACTIVE
    } else {
      this.status = STANDBY
    }
  }

  get isStandby() {
    return this.status === STANDBY
  }

  get isLoading() {
    return this.status === LOADING
  }

  get isArchived() {
    return !this.isVisible
  }
}
