import { isEqual } from 'lodash'
import { getResourceTypes } from '../../api/resource_types'
import ResourceType from '../../models/resource_type'

export const RECEIVE_RESOURCE_TYPES = 'RECEIVE_RESOURCE_TYPES'

const state = () => {
  return {
    all: {},
  }
}

const getters = {
  getById: (state) => (id) => state.all[id] || null,
  getAllResourceTypes: (state) =>
    Object.values(state.all).filter((rt) => rt.isVisible === true),
  getResourceTypesForBusinessUnit:
    (_state, getters, _rootState, rootGetters) => (businessUnitId) => {
      const selectableResourceTypeIds = rootGetters[
        'activities/getActivitiesForBusinessUnit'
      ](businessUnitId).map((activity) => activity.resourceTypeId)

      return getters.getAllResourceTypes.filter((resourceType) =>
        selectableResourceTypeIds.includes(resourceType.id)
      )
    },
}

const actions = {
  async fetchResourceTypes({ commit }) {
    const response = await getResourceTypes()
    commit(RECEIVE_RESOURCE_TYPES, response)
  },
}

const mutations = {
  [RECEIVE_RESOURCE_TYPES](state, { data }) {
    const receivedResourceTypes = {}

    data.forEach((json) => {
      receivedResourceTypes[json.id] = new ResourceType(json)
    })

    if (!isEqual(receivedResourceTypes, state.all)) {
      state.all = receivedResourceTypes
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
