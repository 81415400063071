import axios from 'axios'
import { get, post, put } from './base'

let getSource = axios.CancelToken.source()
let createSource = axios.CancelToken.source()
let updateSource = axios.CancelToken.source()

const endPoint = '/api/v2/tasks'
const v3EndPoint = '/api/v3/tasks'

export function getAll() {
  getSource.cancel('Cancel old get tasks request')
  getSource = axios.CancelToken.source()

  return get(v3EndPoint, { cancelToken: getSource.token })
}

export function create(item) {
  createSource.cancel('Cancel old create tasks request')
  createSource = axios.CancelToken.source()

  return post(endPoint, {
    cancelToken: createSource.token,
    data: {
      name: item.name,
      is_active: item.isActive,
      owner_id: item.ownerId,
    },
  })
}

export function update(item) {
  updateSource.cancel('Cancel old update tasks request')
  updateSource = axios.CancelToken.source()

  return put(`${endPoint}/${item.id}`, {
    cancelToken: updateSource.token,
    data: {
      id: item.id,
      name: item.name,
      is_active: item.isActive,
    },
  })
}
