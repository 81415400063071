import { GetJobEventsQueryResponse } from '@/api/jobs'

export class JobEvent {
  constructor(json: GetJobEventsQueryResponse) {
    this.jobId = json.job_id
    this.id = json.event_id
    this.type = json.event_type
    this.timestamp = json.timestamp
    this.metadata = json.metadata
    this.data = json.data
  }

  jobId: string
  id: string
  type: string
  timestamp: Date
  metadata: Record<string, any> | null
  data: Record<string, any> | null
}
