<template>
  <v-row no-gutters justify="space-between" class="white py-1 px-4 ma-0">
    <v-col class="shrink">
      <v-btn :disabled="!previousButtonEnabled" icon @click="goToPreviousPage">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn :disabled="!nextButtonEnabled" icon @click="goToNextPage">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-col>
    <v-col v-if="getCount" align-self="center">
      <vs-text data-test="forms-bottombar-submission-counts"
        >{{ getCount }} Submissions</vs-text
      >
    </v-col>
    <v-col class="shrink">
      <vs-snackbar v-model="isSnackbarVisible" :type="snackbar.type">
        {{ snackbar.messages[snackbar.messageKey] }}
      </vs-snackbar>
      <vs-button
        label="Download CSV"
        icon="file_download"
        :loading="isCsvDownloading"
        small
        type="secondary"
        @click="downloadCsv()"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { saveFile } from '@/utils/save-file'
import { downloadFormSubmissionsCsv } from '@/api/form_submissions'

export default {
  name: 'FormsBottomBar',
  data() {
    return {
      isCsvDownloading: false,
      isSnackbarVisible: false,
      snackbar: {
        type: 'info',
        timeout: 6000,
        messageKey: null,
        showActions: true,
        completeAll: false,
        messages: {
          csvDownloadError: 'Failed to download CSV. Please try again later.',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      canGotoNextPage: 'formSubmissions/canGotoNextPage',
      canGotoPreviousPage: 'formSubmissions/canGotoPreviousPage',
      pageLoading: 'formSubmissions/getLoading',
      getCount: 'formSubmissions/getCount',
      makeFilterArguments: 'formSubmissionsFilters/makeFilterArguments',
    }),
    previousButtonEnabled() {
      return this.canGotoPreviousPage && !this.pageLoading
    },
    nextButtonEnabled() {
      return this.canGotoNextPage && !this.pageLoading
    },
  },
  methods: {
    ...mapActions({
      fetchNextFormSubmissionPage:
        'formSubmissions/fetchNextFormSubmissionPage',
      fetchPreviousFormSubmissionPage:
        'formSubmissions/fetchPreviousFormSubmissionPage',
    }),
    goToNextPage() {
      this.fetchNextFormSubmissionPage()
    },
    goToPreviousPage() {
      this.fetchPreviousFormSubmissionPage()
    },
    async downloadCsv() {
      this.isCsvDownloading = true

      try {
        saveFile(await downloadFormSubmissionsCsv(this.makeFilterArguments))
      } catch (e) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'csvDownloadError',
        })
      }

      this.isCsvDownloading = false
    },
    setSnackbarData({ type = 'info', messageKey = null }) {
      Object.assign(this.snackbar, { type, messageKey })
      this.isSnackbarVisible = true
    },
  },
}
</script>
