<template>
  <v-menu
    v-model="addFieldMenu"
    :close-on-content-click="true"
    :nudge-width="200"
    offset-x
  >
    <template #activator="{ on, attrs }">
      <vs-button
        v-bind="attrs"
        rounded
        icon="add"
        label="Add a field"
        class="btn"
        full-width
        v-on="on"
      >
      </vs-button>
    </template>
    <v-card class="overflow-y-auto" max-height="40em">
      <v-list-item-group>
        <v-list v-for="type in fieldItems" :key="type.name">
          <v-subheader>{{ type.name }}</v-subheader>
          <template v-for="fieldType in type.items">
            <vs-list-field-item
              v-if="
                fieldType.name === fieldItems.unique.items.subjectLocation.name
              "
              :key="fieldType.name"
              :disabled="subjectLocationFieldExists"
              :item-key="fieldType.name"
              :icon="getFieldIcon(fieldType.build())"
              :label="fieldType.name"
              :description="fieldType.description"
              @add-field="onFieldClicked(fieldType.build(), fieldType.name)"
            >
            </vs-list-field-item>
            <vs-list-field-item
              v-else-if="
                fieldType.name === fieldItems.unique.items.subjectUnit.name
              "
              :key="fieldType.name"
              :disabled="subjectFieldExists"
              :item-key="fieldType.name"
              :icon="getFieldIcon(fieldType.build())"
              :label="fieldType.name"
              :description="fieldType.description"
              @add-field="onFieldClicked(fieldType.build(), fieldType.name)"
            >
            </vs-list-field-item>
            <vs-list-field-item
              v-else
              :key="fieldType.name"
              :item-key="fieldType.name"
              :icon="getFieldIcon(fieldType.build())"
              :label="fieldType.name"
              :description="fieldType.description"
              @add-field="onFieldClicked(fieldType.build(), fieldType.name)"
            >
            </vs-list-field-item>
          </template>

          <v-divider></v-divider>
        </v-list>
      </v-list-item-group>
    </v-card>
  </v-menu>
</template>

<script>
import * as fb from 'ironsight-form-builder'
import VsButton from '@/components/vision/VsButton'
import draggable from 'vuedraggable'
import VsText from '@/components/vision/VsText'
import VsAutocomplete from '@/components/vision/Inputs/VsAutocomplete/VsAutocomplete'
import VsHeading from '@/components/vision/VsHeading'
import VsWrapper from '@/components/vision/VsWrapper'
import formFieldIcon from '@/utils/forms/form-field-icons'
import { ArrayItemDataType } from 'ironsight-form-builder/dist/form_fields/array_field_definition'
import VsTextInput from '@/components/vision/Inputs/VsTextInput'
import {
  BusinessUnitLookup,
  CompanyLookup,
  LocationLookup,
  UserLookup,
} from 'ironsight-form-builder/dist/models/lookup_entity'
import VsListFieldItem from '@/components/form-builder/VsListFieldItem'

export default {
  name: 'AddFieldButton',
  components: {
    VsListFieldItem,
    VsTextInput,
    VsWrapper,
    VsHeading,
    VsAutocomplete,
    VsText,
    VsButton,
    draggable,
  },

  props: {
    subjectLocationFieldExists: {
      type: Boolean,
      default: false,
    },
    subjectFieldExists: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      addFieldMenu: false,
      fieldItems: {
        text: {
          name: 'Text',
          items: {
            singleLine: {
              name: 'Single Line Text',
              description: 'An input for a single line string',
              build: () => {
                const field = new fb.TextEntryFieldDefinition()
                field.isRequired = true
                return field
              },
            },
            multiLine: {
              name: 'Multi Line Text',
              description: 'An input for a multi line string',
              build: () => {
                const field = new fb.TextEntryFieldDefinition()
                field.isMultiline = true
                field.isRequired = true
                return field
              },
            },
          },
        },
        choice: {
          name: 'Choice',
          items: {
            radio: {
              name: 'Dropdown/Radio',
              description: 'A single select in the form of a radio or dropdown',
              build: () => {
                const field = new fb.EnumStringFieldDefinition()
                field.title = 'Radio Field'
                field.isRequired = true
                field.isRadio = true
                return field
              },
            },
            selector: {
              name: 'Grouped Dropdown',
              description: 'A dropdown with sections',
              build: () => {
                const field = new fb.GenericSelectorDefinition()
                field.title = 'Select Me'
                field.isRequired = true
                return field
              },
            },
            boolean: {
              name: 'Yes/No',
              description: 'A boolean, true or false input',
              build: () => {
                const field = new fb.BooleanFieldDefinition()
                field.title = 'Boolean Field'
                field.isRequired = true
                return field
              },
            },
            formButtons: {
              name: 'Form Buttons',
              description:
                'A single select with the options shown as a toggle button',
              build: () => new fb.FormButtonsDefinition(),
            },
            lookupUser: {
              name: 'Choose a User',
              description: 'Choose an IronSight user',
              build: () => new fb.LookupFieldDefinition(new UserLookup()),
            },
            lookupCompany: {
              name: 'Choose a Company',
              description: 'Choose a company',
              build: () => new fb.LookupFieldDefinition(new CompanyLookup()),
            },
            lookupLocation: {
              name: 'Choose a Location',
              description: 'Choose a location',
              build: () => new fb.LookupFieldDefinition(new LocationLookup()),
            },

            lookupBusinessUnit: {
              name: 'Choose a Business Unit',
              description: 'Choose a business unit',
              build: () =>
                new fb.LookupFieldDefinition(new BusinessUnitLookup()),
            },
            creatableSelector: {
              name: 'Creatable Selector',
              description:
                'Similar to a dropdown, select from a dropdown option, or enter your own',
              build: () => new fb.CreateableSelectorDefinition(),
            },
          },
        },
        number: {
          name: 'Number',
          items: {
            number: {
              name: 'Number',
              description: 'A number input',
              build: () => {
                const field = new fb.NumberFieldDefinition()
                field.allowDecimals = true
                field.title = 'Number Field'
                field.isRequired = true
                return field
              },
            },
            int: {
              name: 'Integer',
              description: 'A number input which does not allow decimals',
              build: () => {
                const field = new fb.NumberFieldDefinition()
                field.title = 'Integer Field'
                field.isRequired = true
                return field
              },
            },
          },
        },
        unique: {
          name: 'Unique Fields',
          items: {
            subjectLocation: {
              name: 'Subject Location',
              description:
                'Location used as the location search key. Can only be used once in a form.',
              build: () => new fb.SubjectLocationFieldDefinition(),
            },
            subjectUnit: {
              name: 'Subject',
              description:
                'Business unit used as the subject, submitter, and assignee search key. Can only be used once in a form.',
              build: () => new fb.SubjectFieldDefinition(),
            },
          },
        },
        lists: {
          name: 'List',
          items: {
            number: {
              name: 'Number List',
              description: 'A list of number inputs',
              build: () =>
                new fb.ArrayFieldDefinition(ArrayItemDataType.decimal),
            },
            int: {
              name: 'Integer List',
              description: 'A list of integer inputs',
              build: () =>
                new fb.ArrayFieldDefinition(ArrayItemDataType.integer),
            },
            string: {
              name: 'Text List',
              description: 'A list of text inputs',
              build: () =>
                new fb.ArrayFieldDefinition(ArrayItemDataType.string),
            },
            user: {
              name: 'User List',
              description: 'A list of IronSight users',
              build: () => new fb.ArrayFieldDefinition(ArrayItemDataType.user),
            },
            business: {
              name: 'Business Unit List',
              description:
                'A list of IronSight business units (users, teams, divisions, and organizations)',
              build: () =>
                new fb.ArrayFieldDefinition(ArrayItemDataType.businessUnit),
            },
            location: {
              name: 'Location List',
              description: 'A list of IronSight locations',
              build: () =>
                new fb.ArrayFieldDefinition(ArrayItemDataType.location),
            },
            company: {
              name: 'Company List',
              description: 'A list of IronSight companies',
              build: () =>
                new fb.ArrayFieldDefinition(ArrayItemDataType.company),
            },
          },
        },

        dateTime: {
          name: 'Date & Time',
          items: {
            dateTime: {
              name: 'Date & Time',
              description: 'A date input with time',
              build: () => {
                const field = new fb.DateFieldDefinition()
                field.title = 'Date & Time Field'
                field.isRequired = true
                field.allowTimeOfDaySelection = true
                return field
              },
            },
            date: {
              name: 'Date',
              description: 'A date input',
              build: () => {
                const field = new fb.DateFieldDefinition()
                field.title = 'Date Field'
                field.isRequired = true
                field.allowTimeOfDaySelection = false
                return field
              },
            },
          },
        },
        misc: {
          name: 'Miscellaneous',
          items: {
            latLng: {
              name: 'Lat/Long Selector',
              description:
                'User chooses any point (latitude and longitude) on an embedded map',
              build: () => {
                const field = new fb.LatLntSelectorDefinition()
                field.title = 'Your Location'
                field.description = 'Subtitle'
                return field
              },
            },
            filePicker: {
              name: 'File Picker',
              description: 'Attaches a file to the form',
              build: () => {
                const field = new fb.FileSelectorDefinition()
                field.title = 'Attach a file'
                field.description = 'Pick a PNG, JPG, PDF, DOCX, XLSX or CSV'
                return field
              },
            },
          },
        },
        displayText: {
          name: 'Display Text',
          items: {
            heading: {
              name: 'Heading',
              description:
                'Read only text that contains a heading and a subtext',
              build: () => {
                const field = new fb.HeadingFieldDefinition()
                field.title = 'Heading'
                field.description = 'Subtitle'
                return field
              },
            },
            markdown: {
              name: 'Markdown',
              description: 'Read only text using markdown format',
              build: () => {
                const field = new fb.MarkdownFieldDefinition()
                field.markdown = '# Heading 1'
                return field
              },
            },
          },
        },
        grouped: {
          name: 'Grouped Fields',
          items: {
            object: {
              name: 'Field Group',
              description:
                'A group of fields which are under a header and description',
              build: () => new fb.ObjectFieldDefinition(),
            },
            objectArray: {
              name: 'Field Group List',
              description: 'An addable list of field groups',
              build: () => {
                const field = new fb.ObjectFieldDefinition()
                field.isArray = true
                return field
              },
            },
          },
        },
      },
    }
  },

  methods: {
    getFieldIcon(field) {
      return formFieldIcon(field)
    },
    onFieldClicked(field, fieldTypeTitle) {
      field.fieldTypeTitle = fieldTypeTitle
      this.$emit('field-clicked', field)
      this.$emit('field-clicked-name', fieldTypeTitle)
    },
  },
}
</script>

<style scoped>
.btn,
.btn:hover,
.btn:focus {
  background-color: var(--color-blue--lighter);
  --text-color: var(--color-blue);
}
.delete-btn,
.delete-btn:hover,
.delete-btn:focus {
  background-color: var(--color-red--lighter);
  --text-color: var(--color-red);
}
</style>
