<template>
  <Page title="Form Builder" width="fill">
    <vs-snackbar
      v-if="rightClickedField"
      v-model="showSnackbar"
      :type="actionSelected.action === 'delete_field' ? 'error' : 'info'"
    >
      <b>{{ truncateLabel(getFieldLabel(rightClickedField)) }}</b>
      {{ actionSelected.title }}
    </vs-snackbar>

    <v-menu
      v-model="contextMenuVisible"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on"></div>
      </template>

      <v-list>
        <template v-for="option in contextItems">
          <v-subheader
            v-if="option.header && rightClickedField"
            :key="option.header"
          >
            {{ getFieldLabel(rightClickedField, true) }}
          </v-subheader>

          <v-list-item
            v-else
            :key="option.value"
            :disabled="duplicateDisabled"
            @click="handleContextAction(option.value, rightClickedField)"
          >
            <v-list-item-action>
              <v-icon :color="option.iconColor">{{ option.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title :class="option.color">
                {{ option.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>

    <vs-dialog
      v-if="saveFormDialogVisible"
      visible
      title="Save new form"
      @on-close="toggleSaveFormDialog"
    >
      <vs-wrapper>
        <vs-text>
          Before this form can be filled out or attached to jobs, it will need
          to be enabled in the form administrator page.
        </vs-text>
        <vs-text-input
          label="Form name"
          :value="formName"
          required
          @input="setFormName"
        />
        <v-row justify="end">
          <vs-button
            class="mt-3 mr-3 mb-3"
            label="Save"
            :disabled="!formName"
            @click="saveFormToTenant"
          >
          </vs-button>
        </v-row>
      </vs-wrapper>
    </vs-dialog>
    <vs-dialog
      v-if="updateFormDialogVisible"
      visible
      title="Confirm Changes"
      @on-close="toggleUpdateFormDialog"
    >
      <vs-wrapper>
        <vs-text>Changes made will be applied to the form structure.</vs-text>
        <v-row justify="end">
          <vs-button
            class="mt-3 mr-3 mb-3"
            label="Save"
            :loading="savingChanges"
            @click="saveChanges"
          >
          </vs-button>
        </v-row>
      </vs-wrapper>
    </vs-dialog>
    <v-row class="pt-4">
      <v-col cols="4">
        <v-card class="mx-auto sticky">
          <field-templates-tree-view
            v-if="isEditingFieldDefinitions"
            :selected="selectedFieldDefinition"
            :field-definitions="fieldDefinitions"
            :subject-location-field-exists="canAddSubjectLocationField"
            :subject-field-exists="canAddSubjectField"
            @click-field="updateActiveTemplate"
            @right-click-field="showContextMenu"
            @add-field="addTemplate"
            @reset-editor-visibility="resetToggles"
          >
          </field-templates-tree-view>

          <form-fields-tree-view
            v-else
            :fields-of-page="fieldsOfPage"
            :field-definitions="fieldDefinitions"
            :selected="selectedFormField"
            :active-flow="activeFlow"
            :builder="builder"
            :page-number-selected="selectedPageNumber"
            :subject-location-field-exists="canAddSubjectLocationField"
            :subject-field-exists="canAddSubjectField"
            :edit-reference-fields="editTemplates"
            @click-field="updateActiveFormField"
            @right-click-field="showContextMenu"
            @add-reference-field="addReferenceField"
            @add-field="addFormField"
            @reorder-field="reorderField"
            @change-selected-page="changeSelectedPage"
            @edit-pages-flows="togglePagesAndFlowsSettings"
          >
          </form-fields-tree-view>
        </v-card>
      </v-col>

      <v-col>
        <v-row justify="end">
          <v-col>
            <vs-button
              class="mb-4 ml-auto"
              label="Template Fields"
              type="secondary"
              icon="settings"
              @click="toggleReferenceFieldsEditor"
            />
          </v-col>
          <v-col md="auto">
            <vs-button
              v-if="showSaveButton"
              class="mb-4 ml-auto"
              label="Save"
              :disabled="fieldsOfPage.length === 0"
              @click="onSaveAction"
            />
          </v-col>
          <v-col md="auto">
            <vs-button
              class="mb-4 ml-auto"
              label="Import"
              @click="importFormJson"
            />
          </v-col>
          <v-col md="auto">
            <vs-button
              class="mb-4 ml-auto"
              label="Export"
              @click="exportFormJson"
            />
          </v-col>
          <v-col md="auto">
            <vs-button class="mb-4 ml-auto" label="Preview" @click="preview" />
          </v-col>
        </v-row>
        <vs-alert v-if="editingForm" class="large-text" type="info">
          Editing: <strong>{{ formName }}</strong>
        </vs-alert>
        <component
          :is="fieldEditorType"
          :active-form-field="activeFormField"
          :active-field-definition="activeTemplateField"
          :selected-field-supports-relationships="
            selectedFieldSupportsRelationships
          "
          :builder="builder"
          :field="activeFormField"
          :active-flow="activeFlow"
          :flow-keys="builder.getFlows()"
          :field-type-name="fieldTypeName"
          @modify-form-field="modifyFormField"
          @remove-form-field="removeFormField"
          @modify-template="modifyTemplate"
          @remove-template="removeTemplate"
          @add-page="addPage"
          @delete-page="deletePage"
          @rename-page-title="renamePageTitle"
          @add-flow="addFlow"
          @delete-flow="deleteFlow"
        ></component>
      </v-col>

      <right-drawer :is-visible="isPreviewVisible">
        <schema-based-form
          :key="formKey"
          :business-unit-id="hubId"
          form-id="preview_form"
          @dismiss="dismiss"
        >
        </schema-based-form>
      </right-drawer>
    </v-row>
  </Page>
</template>

<script>
import SchemaBasedForm from '@/components/forms/SchemaBasedForm'
import VsHeading from '@/components/vision/VsHeading'
import RightDrawer from '@/components/common/RightDrawer'
import VsButton from '@/components/vision/VsButton'
import * as fb from 'ironsight-form-builder'
import { v4 as uuidv4 } from 'uuid'
import Page from '@/components/common/Page'

import { mapActions, mapGetters } from 'vuex'
import CoreFieldSettings from '@/components/form-builder/field-settings/CoreFieldSettings'
import SpecificFieldSettings from '@/components/form-builder/field-settings/SpecificFieldSettings'
import RelationshipsEditor from '@/components/form-builder/RelationshipsEditor'
import EditPagesAndFlowsSettings from '@/components/form-builder/EditPagesAndFlowsSettings'
import VsTextInput from '@/components/vision/Inputs/VsTextInput'
import VsWrapper from '@/components/vision/VsWrapper'
import VsDialog from '@/components/vision/VsDialog'
import VsText from '@/components/vision/VsText'
import { ReferenceField } from 'ironsight-form-builder'
import FormFieldDefinitionsEditor from '@/components/form-builder/FormFieldDefinitionsEditor'
import FieldTemplatesEditor from '@/components/form-builder/FieldTemplatesEditor'
import FormFieldsTreeView from '@/components/form-builder/FormFieldsTreeView'
import FieldTemplatesTreeView from '@/components/form-builder/FieldTemplatesTreeView'
import VsSnackbar from '@/components/vision/VsSnackbar'
import formFieldTitle from '@/utils/forms/form-field-title'
import { createForm, updateForm } from '@/api/account-data/manage-forms'
import VsAlert from '@/components/vision/VsAlert.vue'

export default {
  name: 'FormBuilder',
  components: {
    VsAlert,
    VsSnackbar,
    FieldTemplatesTreeView,
    FormFieldsTreeView,
    FormFieldDefinitionsEditor,
    VsText,
    VsDialog,
    VsWrapper,
    VsTextInput,
    EditPagesAndFlowsSettings,
    RelationshipsEditor,
    CoreFieldSettings,
    SpecificFieldSettings,
    Page,
    VsButton,
    VsHeading,
    SchemaBasedForm,
    FieldTemplatesEditor,
    RightDrawer,
  },

  data: () => {
    return {
      editingForm: false,
      fieldMenu: false,
      isPreviewVisible: false,
      formKey: 0,
      search: null,
      caseSensitive: false,
      builder: fb.FormBuilder.newForm(),
      activeFormField: null,
      activeTemplateField: null,
      activeFlow: '#root_schema',
      selectedPageNumber: 0,
      formId: null,
      formName: '',
      formStructure: '',
      saveFormDialogVisible: false,
      updateFormDialogVisible: false,
      savingChanges: false,
      fieldTypeName: null,
      editPagesAndFlows: false,
      editTemplates: false,
      hubId: '',
      rightClickedField: null,
      showSnackbar: false,
      contextMenuVisible: false,
      actionSelected: { title: '', action: '' },
      x: 0,
      y: 0,
      contextItems: [
        { header: ' ' },
        {
          title: 'Duplicate Field',
          value: 'duplicate_field',
          icon: 'content_copy',
        },
        {
          title: 'Delete Field',
          value: 'delete_field',
          color: 'delete',
          icon: 'delete',
          iconColor: 'red',
        },
      ],
    }
  },

  computed: {
    isEditingPagesAndFlowsSettings() {
      return !this.activeFormField && this.editPagesAndFlows
    },
    isEditingFieldDefinitions() {
      return this.editTemplates
    },
    fieldEditorType() {
      if (this.isEditingPagesAndFlowsSettings) {
        return EditPagesAndFlowsSettings.name
      } else if (this.isEditingFieldDefinitions) {
        return FieldTemplatesEditor.name
      } else {
        return FormFieldDefinitionsEditor.name
      }
    },
    ...mapGetters({
      isHostOrganizationUser: 'permissions/isHostOrganizationUser',
      hasAccountOrganizationAccess: 'permissions/hasAccountOrganizationAccess',
      getFormById: 'forms/getById',
    }),
    fieldsOfPage() {
      return this.builder.fieldsOfPage(this.selectedPageNumber, this.activeFlow)
    },
    fieldDefinitions() {
      return this.builder.fieldDefinitions()
    },
    duplicateDisabled() {
      return this.rightClickedField instanceof fb.ObjectFieldDefinition
    },
    showSaveButton() {
      return this.isHostOrganizationUser && this.hasAccountOrganizationAccess
    },
    structure() {
      return this.builder.build()
    },
    selectedFormField() {
      return this.activeFormField
    },
    selectedFieldDefinition() {
      return this.activeTemplateField
    },
    selectedFieldSupportsRelationships() {
      return [
        fb.GenericSelectorDefinition,
        fb.NumberFieldDefinition,
        fb.EnumStringFieldDefinition,
        fb.BooleanFieldDefinition,
        fb.TextEntryFieldDefinition,
        fb.ReferenceField,
      ].some((supportedField) => this.activeFormField instanceof supportedField)
    },
    canAddSubjectLocationField() {
      return (
        this.builder.fieldKeyExists(
          new fb.SubjectLocationFieldDefinition().uniqueKey()
        ) || this.fieldEditorType === FieldTemplatesEditor.name
      )
    },
    canAddSubjectField() {
      return (
        this.builder.fieldKeyExists(
          new fb.SubjectFieldDefinition().uniqueKey()
        ) || this.fieldEditorType === FieldTemplatesEditor.name
      )
    },
  },

  beforeMount() {
    setTimeout(() => {
      this.ifEditingForm()
    }, 1000)
    this.createPreviewForm()
  },

  methods: {
    getFieldLabel(field) {
      return formFieldTitle(field)
    },
    truncateLabel(label) {
      if (label.length > 30) {
        return label.slice(0, 30) + '...'
      }
      return label
    },
    handleContextAction(action, field) {
      this.showSnackbar = true
      switch (action) {
        case 'duplicate_field':
          this.actionSelected = { title: 'Duplicated', action }
          if (this.editTemplates) {
            this.addTemplate(field.copy())
          } else {
            this.addFormField(field.copy())
          }
          break
        case 'delete_field':
          this.actionSelected = { title: 'Deleted', action }
          if (this.editTemplates) {
            this.removeTemplate(field)
          } else {
            this.removeFormField(field)
          }
          break
      }
    },
    showContextMenu(field) {
      this.rightClickedField = field.field
      field.e.preventDefault()
      this.contextMenuVisible = false
      this.x = field.e.clientX
      this.y = field.e.clientY
      this.$nextTick(() => {
        this.contextMenuVisible = true
      })
    },
    resetToggles() {
      this.editTemplates = false
      this.editPagesAndFlows = false
      this.activeFormField = null
      this.activeTemplateField = null
    },
    toggleReferenceFieldsEditor() {
      this.resetToggles()
      this.editTemplates = true
    },
    togglePagesAndFlowsSettings() {
      this.resetToggles()
      this.editPagesAndFlows = true
    },
    ...mapActions({
      createPreviewForm: 'forms/createPreviewForm',
      updatePreviewFormStructure: 'forms/updatePreviewFormStructure',
    }),
    addFlow(flowName) {
      this.builder.addEmptyFlow(flowName)
    },
    deleteFlow(flowKey) {
      this.activeFlow =
        this.builder.getFlows()[this.builder.getFlows().indexOf(flowKey) - 1]
      this.selectedPageNumber = 0
      this.builder.deleteFlow(flowKey)
    },
    addPage(flowKey) {
      let lastPageIndex = this.builder.pageCount(flowKey) - 1
      this.builder.insertBlankPageAfter(lastPageIndex++, flowKey)
    },
    deletePage(page) {
      this.builder.deletePage(page.index, page.flowKey)
      const newActivePageIndex =
        page.index === this.selectedPageNumber
          ? page.index === 0
            ? page.index
            : page.index - 1
          : this.selectedPageNumber
      this.activeFlow = page.flowKey
      this.selectedPageNumber = newActivePageIndex
    },
    renamePageTitle(page) {
      this.builder.editPageTitle(page.title, page.index, page.flowKey)
    },
    changeSelectedPage(page) {
      this.activeFlow = page.flowKey
      this.selectedPageNumber = page.index
      this.resetToggles()
    },
    updateActiveFormField(selectedField) {
      this.editPagesAndFlows = false
      this.activeFormField = selectedField
    },
    updateActiveTemplate(selectedField) {
      this.editPagesAndFlows = false
      this.activeTemplateField = selectedField
    },
    addReferenceField(field) {
      const referenceField = new ReferenceField(field)

      // Vue needs properties to exist, even if they are null,
      // for computed to detect changes based on them
      referenceField.titleOverride = null
      referenceField.descriptionOverride = null

      this.builder.addFieldToPage(
        referenceField,
        this.selectedPageNumber,
        this.activeFlow
      )

      const newIndex = this.fieldsOfPage.indexOf(this.activeFormField)
      if (newIndex !== -1 && this.activeFormField) {
        this.builder.reorderField(referenceField, newIndex + 1)
      }

      this.activeFormField = referenceField
    },
    addFormField(field) {
      this.builder.addFieldToPage(
        field,
        this.selectedPageNumber,
        this.activeFlow
      )

      const newIndex = this.fieldsOfPage.indexOf(this.activeFormField)
      if (newIndex !== -1 && this.activeFormField) {
        this.builder.reorderField(field, newIndex + 1)
      }

      this.activeFormField = field
    },
    removeFormField(field) {
      this.builder.removeField(field)
      this.activeFormField = null
    },
    modifyFormField(data) {
      this.activeFormField[data.propertyKey] = data.fieldValue
    },
    addTemplate(field) {
      this.builder.addFieldDefinition(field)

      const newIndex = this.fieldsOfPage.indexOf(this.activeTemplateField)
      if (newIndex !== -1 && this.activeTemplateField) {
        this.builder.reorderField(field, newIndex + 1)
      }

      this.activeTemplateField = field
    },
    removeTemplate(field) {
      this.builder.deleteFieldDefinition(field)
      this.activeTemplateField = null
    },
    modifyTemplate(data) {
      this.activeTemplateField[data.propertyKey] = data.fieldValue
    },
    reorderField(event) {
      const field = event.moved.element
      const newIndex = event.moved.newIndex
      this.builder.reorderField(field, newIndex)
    },
    togglePreview() {
      this.isPreviewVisible = !this.isPreviewVisible
    },
    preview() {
      this.togglePreview()
      this.updatePreviewFormStructure(this.structure)
      this.formKey++
    },
    dismiss() {
      this.togglePreview()
    },
    async importFormJson() {
      try {
        const [fileHandle] = await window.showOpenFilePicker()
        const fileData = await fileHandle.getFile()

        if (fileData.name.indexOf('.json') === -1) {
          throw new Error('Please select a .json file')
        }

        const text = await fileData.text()
        const json = JSON.parse(text)
        this.builder = new fb.FormParser().parse(json)
      } catch (e) {
        const wasAborted = e.toString().indexOf('The user aborted') !== -1
        if (!wasAborted) {
          alert(e)
        }
      }
    },
    exportFormJson() {
      const json = JSON.stringify(this.builder.build())
      const file = new Blob([json], { type: 'text/json' })

      const a = document.createElement('a')
      const url = URL.createObjectURL(file)
      a.href = url
      a.download = 'form.json'
      document.body.appendChild(a)
      a.click()
      setTimeout(function () {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 0)
    },
    onSaveAction() {
      if (this.editingForm) {
        return this.toggleUpdateFormDialog()
      } else {
        return this.toggleSaveFormDialog()
      }
    },
    toggleSaveFormDialog() {
      this.saveFormDialogVisible = !this.saveFormDialogVisible
    },
    toggleUpdateFormDialog() {
      this.updateFormDialogVisible = !this.updateFormDialogVisible
    },
    async saveFormToTenant() {
      const id = uuidv4()

      await createForm({
        name: this.formName,
        id,
        owner_id: this.$auth.user.org_id,
        structure: this.builder.build(),
        state: 'Active',
        fillable: false,
      })

      this.$router.push({ name: 'form-definition-form', params: { id } })
    },
    setFormName(name) {
      this.formName = name
    },
    async ifEditingForm() {
      this.formId = this.$route.query.formId
      if (this.formId) {
        this.editingForm = true

        const form = this.getFormById(this.formId)
        await this.importForm(form.structure)
        this.formName = form.name
        this.formStructure = form.structure
        this.activeFormField = this.fieldsOfPage[0]
        this.preview()
      }
    },
    importForm(json) {
      try {
        this.builder = new fb.FormParser().parse(json)
      } catch (e) {
        alert(e)
      }
    },
    async saveChanges() {
      this.savingChanges = true
      await updateForm(this.formId, {
        name: this.formName,
        structure: this.structure,
        notificationsEnabled: false,
      })
      this.savingChanges = false
      this.toggleUpdateFormDialog()
    },
  },
}
</script>

<style scoped>
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky !important;
  top: 8vh;
}

.large-text {
  font-size: 16px;
}
</style>
