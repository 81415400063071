import axios from 'axios'
import { get, post } from './base'
import { convertToCamelCase } from '@/models/utils/casing'
import { UnitNumber } from '@/models/unit_number'
import { computed, Ref, unref } from 'vue'
import { useMutation, useQuery } from '@tanstack/vue-query'

let source = axios.CancelToken.source()

const unitsEndPoint = '/api/v2/units'

export function getUnitNumbers() {
  source.cancel('Cancel old get unit numbers request')
  source = axios.CancelToken.source()

  return get(unitsEndPoint, {
    cancelToken: source.token,
  })
}

export function updateUnitStatus(id, status) {
  return post(`${unitsEndPoint}/${id}/status`, {
    data: {
      id,
      status,
    },
  })
}

export function getById(id: number | string, signal?: AbortSignal) {
  source = axios.CancelToken.source()
  const resource = get<UnitNumber>(`${unitsEndPoint}/${id}`, {
    cancelToken: source.token,
  }).then((res) => convertToCamelCase(res.data))

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })

  return resource
}

type MaybeRef<T> = Ref<T> | T

export function useResourceQuery(
  resourceId?: MaybeRef<number | string | null | undefined>
) {
  const computedId = computed(() => unref(resourceId))
  const queryKey = computed(() => ['resource', computedId.value])
  const enabled = computed(() => {
    return computedId.value !== null && computedId.value !== undefined
  })

  return useQuery({
    queryKey,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: ({ signal }) => getById(computedId.value!, signal),
    enabled,
  })
}

export function useUpdateResourceStatusMutation() {
  return useMutation({
    mutationFn: (variables: Record<string, any>) =>
      updateUnitStatus(variables.id, variables.status),
  })
}
