import { computed, ref, Ref } from 'vue'
import {
  FieldViewFilter,
  BatchViewFilter,
  JobFilter,
  JobNumberFilter,
  JobQueueFilter,
  ProjectViewFilter,
} from '@/components/jobs/filters'

type SortInfo = {
  sortBy: Ref<string[]>
  sortDesc: Ref<boolean[]>
}

type OffsetInfo = {
  offset: Ref<number>
  limit: Ref<number> | number
}

export type ArchivedFilter = { archived: boolean }

export type AssignmentGroupFilter = { assignmentGroupNumber: number }

export type SearchJobsQuery = (
  | JobFilter
  | JobQueueFilter
  | JobNumberFilter
  | ProjectViewFilter
  | BatchViewFilter
  | FieldViewFilter
  | AssignmentGroupFilter
  | ArchivedFilter
) &
  SortInfo &
  OffsetInfo

export function useJobsQuery(
  filters:
    | JobFilter
    | JobQueueFilter
    | JobNumberFilter
    | ProjectViewFilter
    | BatchViewFilter
    | FieldViewFilter
    | AssignmentGroupFilter
    | ArchivedFilter,
  currentPage: Ref<number>,
  itemsPerPage = 20,
  sortBy: Ref<string[]> = ref<string[]>([]),
  sortDesc: Ref<boolean[]> = ref<boolean[]>([])
): SearchJobsQuery {
  const offset = computed(() => (currentPage.value - 1) * itemsPerPage)

  return {
    ...filters,
    sortBy,
    sortDesc,
    offset,
    limit: itemsPerPage,
  }
}
