import store from '../store'
import { getInstanceAsync } from './auth'

export const authGuard = async (to, _from, next) => {
  const auth = await getInstanceAsync()

  if (auth.error) {
    return false
  }

  if (to.path === '/portal' && 'session_token' in to.query) {
    return next()
  }

  if (!auth.isAuthenticated) {
    if (to.matched.length > 0) {
      return auth.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    }
    return auth.loginWithRedirect({ appState: { targetUrl: '/' } })
  }

  await store.restored
  if (!store.state.permissions.loaded) {
    await store.dispatch('permissions/fetchCurrentUserContext')
  }

  if (to.matched.length <= 0) {
    return next('/')
  }

  const requiredPermissions = [
    ...new Set(to.matched.flatMap(({ meta = {} }) => meta.permissions || [])),
  ]

  const hasInternalRoutes = to.matched.some(({ meta = {} }) => meta.isInternal)
  const canAccessInternalRoutes =
    hasInternalRoutes && store.getters['permissions/isHostOrganizationUser']

  if (
    (!hasInternalRoutes || canAccessInternalRoutes) &&
    (requiredPermissions.length <= 0 ||
      requiredPermissions.some((role) => auth.hasRole(role)))
  ) {
    return next()
  }

  return next('/')
}
