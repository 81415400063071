<template>
  <span>
    <field-view-job-panel-provision
      v-if="
        id !== 'new' &&
        (parentRouteName === 'field-view-next' ||
          parentRouteName === 'fvnext-info-panel')
      "
      :job-number="id"
    />
    <claim-job-form
      v-if="showClaimForm"
      :number="id"
      @dismiss="dismissClicked"
      @claimed="handleJobClaimed"
    />
    <readonly-job-form
      v-else-if="showReadonlyJob"
      :id="id"
      ref="readonly-job-form"
      :parent-route-name="parentRouteName"
      :job-uuid="jobUuid"
      :is-fetching="isFetching"
      :show-trip-map="showTripMap"
      :load-history-error="loadHistoryError"
      :extension-schema-id="extensionSchemaId"
      :extension-schema="extensionSchema.data"
      :history="history"
      :comments="comments"
      :map-link="mapLink"
      @dismiss="dismissClicked"
      @refresh-job-history="setJobHistory"
      @create-comment="createComment"
      @update-readonly-job="handleUpdateReadonlyJob"
    />
    <FormLayout
      v-else
      data-test="job-side-sheet"
      :title="titleLabel"
      :is-loading="loading"
      :has-changes="draftChangesDetected"
      :is-saving="isJobSaving || isCreatingJobs"
      :save-label="saveButtonLabel"
      @on-save="saveTicket"
      @on-dismiss="dismissClicked"
      @[canDiscard]="handleDiscard"
    >
      <template #label>
        <job-status-chip
          v-if="status"
          data-test="job-status"
          :status="finalStatus"
        />
      </template>

      <template v-if="!isFetching" #breadcrumbs>
        <project-breadcrumbs
          v-if="jobId"
          data-test="job-title"
          :job-id="jobId"
          :job-display-name="titleLabel"
          :route="projectSideSheetRoute"
        />

        <vs-heading v-if="company?.name" type="subhead">{{
          company?.name
        }}</vs-heading>
      </template>

      <div v-if="!isFetching && !loadJobError" class="description">
        <created-info
          data-test="job-creation-description"
          :creator-sid="isNewTicket ? $auth.user.sub : createdBy"
          :created-time="created"
        />
      </div>

      <JobErrorBanner />

      <JobReadonlyBanner
        v-if="!loadJobError && isReadonly"
        :is-archived="isArchived"
        :job-status="status"
        :hub-id="jobHubId"
      />

      <TicketForm
        v-if="!loadJobError"
        ref="ticket-form"
        :job-id.sync="jobId"
        :job-uuid="jobUuid"
        :unit-working-status="unitWorkingStatus"
        :active-duration="activeDuration"
        :priority-id.sync="priorityId"
        :owner-id.sync="ownerId"
        :account-id="accountId"
        :customer-sid.sync="customerSid"
        :followers.sync="followers"
        :start-time.sync="startTime"
        :end-time.sync="endTime"
        :activity-id.sync="activityId"
        :resource-type-id.sync="resourceTypeId"
        :inventory.sync="inventory"
        :crew.sync="crew"
        :unit-id.sync="unitId"
        :service-provider-business-unit-id.sync="serviceProviderBusinessUnitId"
        :cost-center.sync="costCenter"
        :details.sync="details"
        :destination-location-id.sync="destinationLocationId"
        :attachments-storage-id="attachmentsStorageId"
        :custom-data.sync="customData"
        :tags.sync="tags"
        :time-slot-dates.sync="timeSlotDates"
        :time-slot-start.sync="timeSlotStart"
        :time-slot-duration.sync="timeSlotDuration"
        :bulk-create.sync="bulkCreate"
        :forms="formsWithSubmissionInfo"
        :form-submissions="adhocFormSubmissions"
        :division-array="divisionArray"
        :user-array="userArrayWithDisabled"
        :is-fetching="loading"
        :custom-fields-schema="getJobCustomFieldsSchema"
        :approval-status="approvalStatus"
        :attachments.sync="attachments"
        :is-readonly="isReadonly"
        :is-cancelled="isCancelled"
        :is-new="isNewTicket"
        :job-status="status"
        :assignment-group-number="assignmentGroupNumber"
        :hub-id="jobHubId"
        :load-history-error="loadHistoryError"
        :history="history"
        :comments="comments"
        :can-modify-requester="canModifyRequester"
        :can-schedule-jobs-in-past="canScheduleJobsInPast"
        :can-modify-priority="canModifyPriority"
        :pool-id="poolId"
        :assignment-form-requirements="assignmentFormRequirements"
        :batch-id="invoiceId"
        :map-link="mapLink"
        :signature="signature"
        :error-fetching-extension-schema="extensionSchemaFetchError"
        :extension-schema-id.sync="extensionSchemaId"
        :extension-schema="extensionSchema"
        @remove-job-from-group="removeJobFromGroup"
        @unsupported-file-selected="unsupportedFileSelected"
        @error-uploading-attachment="errorUploadingAttachment"
        @show-form-submission="showFormSubmission"
        @fill-form-submission="fillFormSubmission"
        @fill-form-requirement="showFormRequirementBottomSheet"
        @view-form-requirement-submission="showFormRequirementBottomSheet"
        @show-form-selection="showFormSelection"
        @show-assignment-form-selection="showAssignmentFormSelection"
        @edit-form-submission="editFormSubmission"
        @create-comment="createComment"
        @update-start-time="handleUpdateStartTime"
        @auto-fill="autoFill"
        @update-pool-execution-plan="updatePoolExecutionPlan"
        @clear-assigned-pool="clearAssignedPool"
        @update:assignment-form-requirements="updateAssignmentFormRequirements"
        @pool:closed="handlePoolClosed"
        @add-location:open="showBottomSheet"
        @add-location:close="hideBottomSheet"
        @show-line-item-bottom-sheet="showLineItemBottomSheet"
        @show-new-guest-user-bottom-sheet="showNewGuestUserBottomSheet"
        @retry-custom-schema-fetch="fetchExtensionSchema"
      />

      <!-- Dialogs -->
      <SendEmailDialog
        v-if="jobId"
        v-model="isEmailDialogVisible"
        :persistent="true"
        :job-id="jobId"
        @set-email-dialog-visibility="setEmailDialogVisibility"
        @notification-sent="handleNotificationSent"
      />

      <template #snack-bar-wrapper>
        <vs-snackbar v-model="isSnackbarVisible" :type="snackbar.type" sticky>
          {{ snackbar.messages[snackbar.messageKey] }}
        </vs-snackbar>
      </template>

      <template v-if="showActionBar" #action-bar>
        <v-menu v-model="showingPdfMenu" offset-y>
          <template #activator="{}">
            <ActionBar
              :job-id="jobId"
              full-width
              :is-pdf-downloading="isPdfDownloading"
              :is-saving="isJobSaving || isCreatingJobs"
              @on-click="handleActionBarClick"
            />
          </template>
          <v-list>
            <v-list-item
              v-for="format in pdfFormats"
              :key="format.id"
              @click="downloadPdf(format.id)"
            >
              <v-list-item-title>{{ format.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template #bottom-sheet>
        <vs-bottom-sheet
          :is-visible="isBottomSheetVisible"
          :full-height="!isBatchVisible"
        >
          <project-selection-page
            v-if="jobId && isProjectVisible"
            :job-id="jobId"
            :owner-id="jobHubId"
            @job-added-success="addToProject"
            @job-added-failure="handleJobAddedFailure"
            @dismiss="closeProjectBottomSheet"
          />

          <batch-selection-page
            v-if="jobId && isBatchVisible"
            :job-uuid="jobUuid"
            :hub-id="jobHubId"
            @dismiss="closeBatchBottomSheet"
            @job-added-success="addToBatch"
            @job-added-failure="handleJobAddedFailure"
          />

          <form-submission
            v-if="shouldShowFormSubmission"
            :form-submission-id="selectedFormSubmissionId"
            parent-job-is-approved="false"
            @dismiss="closeFormSubmission"
            @edited="updatedAttachedFormSubmission"
          />

          <form-submission
            v-else-if="shouldShowFormRequirementSubmission"
            :form-submission-id="selectedFormRequirement.formSubmissionId"
            @dismiss="closeFormRequirementBottomSheet"
            @edit="updatedFormRequirementSubmission"
          />

          <schema-based-form
            v-else-if="
              shouldShowFillFormSubmission || isEditingJobFormSubmission
            "
            :form-id="selectedFormId"
            :business-unit-id="jobHubId"
            :form-submission-id="selectedFormSubmissionId"
            @dismiss="closeSchemaBasedForm"
            @submitted="
              (e) =>
                isEditingJobFormSubmission
                  ? updatedAttachedFormSubmission()
                  : attachFormSubmission(e)
            "
          />

          <schema-based-form
            v-else-if="shouldShowFillFormRequirement"
            :form-id="selectedFormRequirement.formId"
            :business-unit-id="jobHubId"
            :form-submission-id="selectedFormSubmissionId"
            @dismiss="closeFormRequirementBottomSheet"
            @submitted="attachFormRequirement"
          />

          <new-form-selection-bottom-sheet
            v-if="isFormSelectionVisible"
            data-test="new-from-selection"
            :forms="fillableForms"
            @select="selectNewForm"
            @dismiss="closeNewFormSelection"
          />

          <new-form-selection-bottom-sheet
            v-if="isAssignmentFormSelectionVisible"
            :forms="assignmentFormRequirementOptions"
            @select="addAssignmentFormRequirement"
            @dismiss="closeAssignmentFormSelection"
          />

          <line-item-form
            v-if="isLineItemBottomSheetVisible"
            :job-id="jobUuid"
            :assigned-to-service-provider-id="serviceProviderBusinessUnitId"
            :hub-id="jobHubId"
            @dismiss="hideLineItemBottomSheet"
          />

          <JobNewGuestUserForm
            v-if="isNewGuestUserBottomSheetVisible"
            @dismiss="hideNewGuestUserBottomSheet"
            @user-created="(userSid) => (customerSid = userSid)"
          />

          <portal-target name="job-bottom-sheet" />
        </vs-bottom-sheet>
      </template>
    </FormLayout>
  </span>
</template>

<script>
import { ref } from 'vue'
import { isEqual, orderBy } from 'lodash'
import {
  CLEAR_TICKETS_ERROR,
  SET_SHOULD_NAVIGATE,
} from '@/store/modules/tickets'
import { ERROR_SAVING_JOB, SAVED_JOB, SAVING_JOB } from '@/store/modules/jobs'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { priorities } from '@/utils/constants'
import ActionBar from './ActionBar'
import { actionTypes } from './ActionTypes'
import FormLayout from '../common/FormLayout'
import SendEmailDialog from './SendEmailDialog'
import ReadonlyJobForm from './ReadonlyJobForm'
import TicketForm from './TicketForm'
import JobStatusChip from '@/components/tickets/JobStatusChip'
import JobHistory from '@/models/job-history'
import JobErrorBanner from './JobErrorBanner.vue'
import JobReadonlyBanner from './JobReadonlyBanner.vue'
import FormSubmission from '../forms/FormSubmission.vue'
import {
  ASSIGNED_TO_POOL,
  ASSIGNED_TO_SERVICE_PROVIDER,
  CANCELLED,
  COMPLETED,
  DRAFT,
  NO_APPROVAL,
  REQUESTED,
} from '@/models/job'
import ProjectSelectionPage from '../project/ProjectSelectionPage.vue'
import ProjectBreadcrumbs from '../project/ProjectBreadcrumbs.vue'
import { TASK_NOT_STARTED } from '@/models/unit_number'
import SchemaBasedForm from '@/components/forms/SchemaBasedForm'
import { getJobHistory, getJobPdf } from '@/api/jobs'
import { saveFile } from '@/utils/save-file'
import { inventoriesAreEqual } from '@/utils/inventories'
import NewFormSelectionBottomSheet from '@/components/forms/NewFormSelectionBottomSheet'
import {
  applyCustomDataOnCloneEffects,
  prepareCustomDataForSubmission,
} from '@/utils/field-schema-helper'
import ClaimJobForm from '@/components/jobs/ClaimJobForm.vue'
import CreatedInfo from '@/components/common/CreatedInfo.vue'
import JobAssignmentFormRequirement from '@/models/job-assignment-form-requirement'
import FieldViewJobPanelProvision from '@/components/field-view-next/FieldViewJobPanelProvision.vue'
import { useHubContextProvider } from '@/common/useHubs'
import LineItemForm from '@/components/jobs/line-items/LineItemForm.vue'
import JobNewGuestUserForm from '@/components/jobs/JobNewGuestUserForm.vue'
import BatchSelectionPage from '@/components/batches/BatchSelectionPage.vue'
import { removeJobsFromInvoice } from '@/api/invoice'
import { useGrantLocationToHub } from '@/api/locations'
import { getExtensionSchema } from '@/api/schemas'
import { resolveEmptyFormDataForSubmission } from '@/utils/forms/form-resolver'

const resetTime = (hours, minutes, diff) => {
  return moment()
    .hours(hours)
    .minutes(minutes)
    .add(diff, 'minutes')
    .seconds(0)
    .milliseconds(0)
}

const newPriorityId = priorities.find((p) => p.name === 'New').id
const normalPriorityId = priorities.find((p) => p.name === 'Normal').id

export default {
  name: 'Ticket',
  components: {
    JobNewGuestUserForm,
    BatchSelectionPage,
    LineItemForm,
    FieldViewJobPanelProvision,
    CreatedInfo,
    ClaimJobForm,
    NewFormSelectionBottomSheet,
    SchemaBasedForm,
    JobStatusChip,
    ActionBar,
    FormLayout,
    SendEmailDialog,
    ReadonlyJobForm,
    TicketForm,
    FormSubmission,
    JobReadonlyBanner,
    JobErrorBanner,
    ProjectSelectionPage,
    ProjectBreadcrumbs,
  },
  beforeRouteEnter(to, _from, next) {
    next(async (vm) => {
      vm.clearTicketsError()

      if (to.params.id === 'new') {
        vm.initializeJobProperties()
      } else if (!isNaN(parseInt(to.params.id, 10))) {
        const idInt = parseInt(to.params.id, 10)

        let job = await vm.fetchJob(idInt)
        if (vm.ticketsError) {
          vm.isFetching = false
          vm.loadJobError = true

          if (vm.ticketsError?.response?.status === 404) {
            vm.removeJobFromStore(idInt)
          } else {
            // We want sentry to log when a job fails to load for reasons other than a 404
            throw new Error(vm.ticketsError)
          }

          return
        }

        if (
          job.priorityId === newPriorityId &&
          job.isNotCompleteOrCancelled &&
          !job.isArchived &&
          vm.canModifyPriority
        ) {
          await vm.updatePriority({
            jobIds: [idInt],
            priorityId: normalPriorityId,
          })
          job = vm.getJobById(idInt)
        }

        vm.fetchProject(job.projectId)
        vm.setJobProperties(job)
        vm.setJobHistory()
      }

      vm.isFetching = false
    })
  },
  beforeRouteLeave(to, _from, next) {
    let isGoingToLeave = true

    // @TODO This is coupled to the route I am going to and only works if we are going back to the ticket list
    if (this.draftChangesDetected && !to.params.isJobSaved) {
      const confirmation = window.confirm(
        'There are unsaved changes, are you sure you want to discard them?'
      )

      if (!confirmation) {
        isGoingToLeave = false
      }
    }

    if (!isGoingToLeave) {
      this.setShouldNavigate({ navigate: false })
      next(false)
    } else {
      this.setShouldNavigate({ navigate: true })

      this.isFetching = true

      this.clearTicketsError()

      this.isSnackbarVisible = false
      next()
    }
  },
  async beforeRouteUpdate(to, from, next) {
    this.isCreatingJobs = false
    if (to.params.id === 'new') {
      if (to.query?.fromJob) {
        const job = this.getJobById(to.query?.fromJob)

        this.resetCloneJob(job)
      } else {
        this.initializeJobProperties()
      }
    } else if (!isNaN(parseInt(to.params.id, 10))) {
      const idInt = parseInt(to.params.id, 10)
      const job = await this.fetchJob(idInt)

      this.fetchProject(job.projectId)
      this.setJobProperties(job)
      this.setJobHistory()
    } else if (to.query.claimed) {
      const job = this.getJobById(this.jobId)
      this.setJobProperties(job)
      await this.setJobHistory()

      const { claimed, ...query } = this.$route.query
      await this.$router.replace({ query })
    }

    // It is possible to change routes while the update handler executes causing an error
    if (this.$router.currentRoute.name === from.name) {
      next()
    } else {
      next(false)
    }
  },
  props: {
    onSaveRoute: {
      type: String,
      default: 'ticket-form',
      required: false,
    },
    parentRouteName: {
      type: String,
      required: true,
    },
    parentRouteParams: {
      type: Object,
      default: () => {},
    },
    id: {
      type: [String, Number],
      default: 'new',
      required: true,
    },
    hubId: {
      type: String,
      default: '',
    },
    locationId: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    /*
     * "Providing" a hubId is a little tricky, since sometimes we have a hub
     * id on the props, othertimes its only available after the job is loaded.
     * We can deal with that by "providing" a ref, and then later updating the
     * ref's value once the job loads.
     */
    const contextualHubId = ref(props.hubId)
    useHubContextProvider(contextualHubId)
    const {
      mutateAsync: grantLocation,
      isLoading: grantingLocation,
      error: grantLocationError,
    } = useGrantLocationToHub()

    return {
      contextualHubId,
      grantLocation,
      grantingLocation,
      grantLocationError,
    }
  },
  data() {
    return {
      isCreatingJobs: false,
      isFetching: true,
      loadJobError: false,
      isEmailDialogVisible: false,
      ticket: null,
      isProjectVisible: false,
      isBatchVisible: false,
      selectedFormSubmissionId: null,
      selectedFormId: null,
      isFormSelectionVisible: false,
      selectedFormRequirement: null,
      isAssignmentFormSelectionVisible: false,
      isEditingJobFormSubmission: false,
      isPortalBottomSheetVisible: false,
      isLineItemBottomSheetVisible: false,
      isNewGuestUserBottomSheetVisible: false,
      isClonedTicket: false,
      selectedLineItem: null,
      timeSlotDates: [],
      timeSlotStart: null,
      timeSlotDuration: 1,
      poolId: null,
      poolExecutionPlan: null,
      bulkCreate: false,
      showingPdfMenu: false,
      snackbar: {
        type: 'info',
        timeout: 6000,
        messageKey: null,
        showActions: true,
        messages: {
          clone: 'Job cloned',
          saved: 'Job saved',
          versionUpdate: 'Activity fields have changed. Check before saving.',
          failedToFetchSchema: 'Unable to load activity fields',
          bulkCreateNoDateError: 'Please select at least one date',
          bulkCreateLimitError: 'Please reduce the number of jobs to create',
          bulkCreateSaveError: 'Failed to create job(s)',
          validationError: 'Please correct the errors on the form',
          notificationSent: 'Notifications sent',
          statusUpdated: 'Status updated',
          archived: 'Archived',
          unarchived: 'Unarchived',
          unsupportedAttachmentType: 'This file type is not supported',
          unsupportedAttachmentSize: 'File size must be under 25 MB',
          errorUploadingAttachment: 'Failed to upload attachment',
          addToExistingProjectError:
            "Couldn't add to project, please try again",
          addToNewProjectError:
            "Project created, but couldn't add this job to it",
          addToBatchError:
            "Couldn't add job to the selected batch, please try again",
          removeJobFromBatchError: 'Failed to remove job from batch',
          removeJobFromBatchSuccess: 'Successfully removed job from batch',
          removeJobFromProjectError: 'Failed to remove job from project',
          removeJobFromGroupError: 'Failed to remove job from group',
          attachedForm: 'Form attached to the job',
          attachFormError: 'Failed to attach form to the job',
          updatedAttachedFormSubmission: 'Form submission has been updated',
          ironSightFormsNotAllFilled:
            'All forms must be filled out before completing the job',
          pdfDownloadError: 'Failed to download PDF',
          genericError: 'Failed to save job',
          removedJobFromGroup: 'Job removed from group',
          attachFormRequirement: 'Form attached to the job',
          attachFormRequirementError: 'Failed to attach form to the job',
          updatedFormRequirementSubmission: 'Form submission has been updated',
          grantLocationsToHubError: 'Cannot grant locations to hub',
        },
      },
      isSnackbarVisible: false,
      resourceTypeId: null,
      // Job properties
      jobId: null,
      jobUuid: null,
      jobHubId: null,
      status: null,
      isArchived: false,
      approvalStatus: null,
      priorityId: 0,
      ownerId: null,
      accountId: null,
      customerSid: null,
      followers: [],
      startTime: null,
      endTime: null,
      activityId: null,
      inventory: [],
      crew: [],
      unitId: null,
      serviceProviderId: null,
      serviceProviderBusinessUnitId: null,
      costCenter: null,
      details: null,
      destinationLocationId: null,
      attachmentsStorageId: null,
      extensionSchemaId: null,
      customData: {},
      projectId: null,
      tags: [],
      created: null,
      createdBy: null,
      unitWorkingStatus: null,
      activeDuration: null,
      formSubmissions: [],
      forms: [],
      isPdfDownloading: false,
      attachments: [],
      comments: [],
      history: [],
      loadHistoryError: false,
      assignmentGroupNumber: null,
      BULK_JOB_LIMIT: 100,
      assignmentFormRequirements: [],
      invoiceId: null,
      signature: null,
      extensionSchema: {},
      extensionSchemaFetchError: null,
    }
  },
  computed: {
    ...mapState(['initialized']),
    ...mapState('tickets', {
      ticketsError: 'error',
    }),
    ...mapState('jobs', {
      supplementaryDataLoaded: 'supplementaryDataLoaded',
    }),
    ...mapState('projects', {
      removeJobError: 'removeJobError',
    }),
    ...mapState('businessUnits', {
      allBusinessUnits: 'all',
    }),
    ...mapGetters({
      getJobById: 'jobs/getById',
      getTaskById: 'tasks/getById',
      unitNumbers: 'unitNumbers/getVisibleUnits',
      divisionsForHub: 'divisions/getDivisionsForHub',
      getUsersRelatedToBusinessUnit: 'users/getUsersRelatedToBusinessUnit',
      userBySid: 'users/getBySid',
      shouldNavigate: 'tickets/getShouldNavigate',
      getJobCustomFieldsSchema: 'schemas/getJobCustomFieldsSchema',
      visibleActivities: 'activities/getVisibleActivities',
      isJobSaving: 'jobs/getSaving',
      canModifyScheduledOrInProgressJob:
        'permissions/canModifyScheduledOrInProgressJob',
      canModifyRequester: 'permissions/canModifyRequester',
      canScheduleJobsInPast: 'permissions/canScheduleJobsInPast',
      canModifyPriority: 'permissions/canModifyPriority',
      getForms: 'forms/getAllVisible',
      getFormDefinitionById: 'forms/getById',
      jobTripMapEnabled: 'featureFlags/jobTripMapEnabled',
      customFieldVersioningEnabled: 'featureFlags/customFieldVersioningEnabled',
      getRolesForBusinessUnit: 'permissions/getRolesForBusinessUnit',
      getByOrganizationId: 'companies/getByOrganizationId',
      getActivityById: 'activities/getById',
      canClaimJob: 'permissions/canClaimJob',
      isHubUser: 'permissions/isHostOrganizationUser',
    }),
    showClaimForm() {
      return this.status === ASSIGNED_TO_POOL && this.canClaimJob(this.jobHubId)
    },
    showReadonlyJob() {
      return this.status === COMPLETED
    },
    hasBeenScheduled() {
      return !(
        this.status === DRAFT ||
        this.status === REQUESTED ||
        this.status === ASSIGNED_TO_SERVICE_PROVIDER
      )
    },
    isReadonly() {
      if (this.isArchived) {
        return true
      }
      if (this.status === COMPLETED) {
        return true
      }
      if (this.hasBeenScheduled) {
        return !this.canModifyScheduledOrInProgressJob(this.jobHubId)
      }

      return false
    },
    isCancelled() {
      return this.status === CANCELLED
    },
    isOnMap() {
      return (
        this.parentRouteName === 'field-view-next' ||
        this.parentRouteName === 'fvnext-info-panel'
      )
    },
    showTripMap() {
      return (
        this.status === COMPLETED && this.jobTripMapEnabled && !this.isOnMap
      )
    },
    mapLink() {
      return this.jobId && !this.isOnMap ? `/map/jobs/${this.jobId}` : null
    },
    loading() {
      return (
        this.isFetching || !this.initialized || !this.supplementaryDataLoaded
      )
    },
    projectSideSheetRoute() {
      const routePrefix = this.onSaveRoute.split('-')[0]
      return `${routePrefix}-project`
    },
    batchSideSheetRoute() {
      return 'job-search-batches-info-panel'
    },
    users() {
      return this.getUsersRelatedToBusinessUnit(this.jobHubId)
    },
    userArrayWithDisabled() {
      const rolesNotAllowedToFollowJob = [
        'Member',
        'Operator',
        'Form Reviewer',
        'Analytics Viewer',
      ]
      if (!this.followers.length) {
        return this.users.filter((u) => {
          const allowedRoles = u.roles.filter(
            (role) => !rolesNotAllowedToFollowJob.includes(role)
          )
          return allowedRoles.length > 0
        })
      }

      const selectedDisabledUsers = this.followers.filter(
        (s) => !this.users.map((u) => u.id).includes(s)
      )

      return this.users
        .concat(selectedDisabledUsers.map((u) => this.userBySid(u)))
        .filter(Boolean)
        .filter((u) => {
          const allowedRoles = u.roles.filter(
            (role) => !rolesNotAllowedToFollowJob.includes(role)
          )
          return allowedRoles.length > 0
        })
    },
    divisionArray() {
      return orderBy(this.divisionsForHub(this.jobHubId), [
        (division) => division.name.toLowerCase(),
      ])
    },
    titleLabel() {
      if (this.isNewTicket) {
        return this.$route.query?.fromJob ? 'Cloned Job' : 'New Job'
      } else if (!isNaN(parseInt(this.$route.params.id, 10))) {
        return `Job #${this.$route.params.id}`
      } else {
        return ''
      }
    },
    saveButtonLabel() {
      return 'Save'
    },
    draftChangesDetected() {
      if (this.loadJobError || this.showReadonlyJob) return false

      const job = this.getJobById(this.jobId)
      if (!job && this.formIsEmpty) return false
      if (!job && !this.formIsEmpty) return true

      return (
        this.priorityId !== job.priorityId ||
        this.ownerId !== job.ownerId ||
        this.customerSid !== job.customerSid ||
        this.activityId !== job.activityId ||
        this.unitId !== job.unitId ||
        this.serviceProviderBusinessUnitId !==
          job.serviceProviderBusinessUnitId ||
        this.poolId !== job.poolId ||
        this.poolExecutionPlan != null ||
        this.costCenter !== job.costCenter ||
        this.details !== job.details ||
        this.destinationLocationId !== job.destinationLocationId ||
        this.resourceTypeId !== job.resourceTypeId ||
        !this.startTime.isSame(job.startTime) ||
        !this.endTime.isSame(job.endTime) ||
        !isEqual(this.crew, job.crew) ||
        !isEqual(this.followers, job.followers) ||
        !inventoriesAreEqual(job.inventory, this.inventory) ||
        !isEqual(
          // The existing job.customData from the server is already "sanitized". Therefore, before
          // comparing to the server data, we also need to sanitize the data
          resolveEmptyFormDataForSubmission(this.customData),
          // Job's custom data created by custom integrations are not sanitized. Therefore, we also
          // need to sanitize the server data before doing the comparison
          resolveEmptyFormDataForSubmission(job.customData)
        ) ||
        !isEqual(this.tags, job.tags) ||
        !isEqual(
          this.assignmentFormRequirements,
          job.assignmentFormRequirements
        )
      )
    },
    formIsEmpty() {
      return !(
        this.priorityId !== 1 ||
        this.ownerId ||
        this.customerSid ||
        this.activityId ||
        this.unitId ||
        this.serviceProviderBusinessUnitId ||
        this.costCenter ||
        this.details ||
        this.resourceTypeId ||
        this.destinationLocationId ||
        this.crew.length > 0 ||
        this.followers.length > 0 ||
        this.inventory.length > 0 ||
        Object.keys(this.customData).length > 0 ||
        this.tags.length > 0
      )
    },
    isNewTicket() {
      return this.id === 'new'
    },
    isNewDraftTicket() {
      return this.jobId === null
    },
    showActionBar() {
      return !this.isNewDraftTicket
    },
    canDiscard() {
      return this.isNewDraftTicket ? null : 'on-discard'
    },
    startEndDuration() {
      return moment.duration(this.endTime.diff(this.startTime)).asHours()
    },
    isBottomSheetVisible() {
      return (
        this.isBatchVisible ||
        this.isProjectVisible ||
        !!this.selectedFormSubmissionId ||
        !!this.selectedFormId ||
        this.isFormSelectionVisible ||
        this.isPortalBottomSheetVisible ||
        this.isAssignmentFormSelectionVisible ||
        !!this.selectedFormRequirement ||
        this.isLineItemBottomSheetVisible ||
        this.isNewGuestUserBottomSheetVisible
      )
    },
    formsWithSubmissionInfo() {
      return this.forms.map((form) => {
        const submission = this.formSubmissions.find(
          (s) => s.formId === form.formUuid
        )

        return submission
          ? Object.assign({}, form, submission, { isFilledOut: true })
          : Object.assign(form, { isFilledOut: false })
      })
    },
    adhocFormSubmissions() {
      return this.formSubmissions
        .filter(
          (fs) => this.forms.find((f) => f.formUuid === fs.formId) === undefined
        )
        .map((fs) => {
          return {
            formName: this.getFormDefinitionById(fs.formId)?.name ?? 'Form',
            formSubmissionId: fs.formSubmissionUuid,
            formId: fs.formId,
            submittedBy: fs.submittedBy,
            submittedOn: fs.submittedOn,
          }
        })
    },
    shouldShowFillFormSubmission() {
      return this.jobId && this.selectedFormId
    },
    shouldShowFormSubmission() {
      return (
        this.jobId &&
        this.selectedFormId &&
        this.selectedFormSubmissionId &&
        !this.isEditingJobFormSubmission
      )
    },
    shouldShowFormRequirementSubmission() {
      return (
        !this.isNewDraftTicket &&
        this.selectedFormRequirement &&
        this.selectedFormRequirement.formSubmissionId !== null
      )
    },
    shouldShowFillFormRequirement() {
      return (
        !this.isNewDraftTicket &&
        this.selectedFormRequirement &&
        this.selectedFormRequirement.formSubmissionId === null
      )
    },
    finalStatus() {
      return this.approvalStatus !== NO_APPROVAL
        ? this.approvalStatus
        : this.status.trim()
    },
    fillableForms() {
      return this.getForms
        .filter((f) => f.fillable)
        .filter((f) => {
          const accessGrant = f.accessGrants.find(
            (ag) => ag.businessUnitId === this.jobHubId
          )
          if (!accessGrant) return false

          const userRoles = this.getRolesForBusinessUnit(this.jobHubId)

          return userRoles.some(
            (role) => !accessGrant.roleRestrictions.includes(role)
          )
        })
    },
    assignmentFormRequirementOptions() {
      return this.getForms
        .filter((f) => f.fillable)
        .filter((f) => {
          const businessUnitId =
            this.company.organizationId === this.serviceProviderBusinessUnitId
              ? this.jobHubId
              : this.serviceProviderBusinessUnitId
          const accessGrant = f.accessGrants.find(
            (ag) => ag.businessUnitId === businessUnitId
          )
          if (!accessGrant) return false

          const userRoles = this.getRolesForBusinessUnit(businessUnitId)
          return userRoles.some(
            (role) => !accessGrant.roleRestrictions.includes(role)
          )
        })
    },
    company() {
      const hub = this.allBusinessUnits[this.jobHubId]
      return this.getByOrganizationId(hub?.ownerId)
    },
    /**
     * A merge of the activity, division, and global defs into 1 custom fields
     * JSON schema.
     */
    customFieldsSchema() {
      // TODO(aaron) we pass getJobCustomFieldsSchema into TicketForm, only to
      // rely on its computed rootSchema. Cleanup separation of concerns,
      // parent/child relation.
      return this.showReadonlyJob
        ? this.$refs['readonly-job-form']?.rootSchema
        : this.$refs['ticket-form']?.rootSchema
    },
    pdfFormats() {
      const activity = this.getActivityById(this.activityId)
      return (
        this.company?.pdfFormats?.filter((p) =>
          activity?.pdfFormatIds.includes(p.id)
        ) ?? []
      ).sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
    },
  },
  watch: {
    extensionSchemaId() {
      this.fetchExtensionSchema()
    },
  },
  beforeDestroy() {
    this.isSnackbarVisible = false
    this.clearTicketsError()
  },
  methods: {
    ...mapActions('projects', ['removeJob', 'fetchMissingProjectsByIds']),
    ...mapActions('jobs', [
      'createJobs',
      'updateJob',
      'updateJobStatus',
      'archiveJob',
      'unarchiveJob',
      'fetchJob',
      'updatePriority',
      'attachFormSubmissionId',
      'attachFormRequirementSubmission',
      'createJobComment',
      'removeJobFromAssignmentGroup',
      'addAttachment',
    ]),
    ...mapMutations('tickets', {
      setShouldNavigate: SET_SHOULD_NAVIGATE,
      clearTicketsError: CLEAR_TICKETS_ERROR,
    }),
    ...mapMutations({
      removeJobFromStore: 'jobs/REMOVE_JOB',
    }),
    ...mapMutations('jobs', {
      savingJob: SAVING_JOB,
      savedJob: SAVED_JOB,
      errorSavingJob: ERROR_SAVING_JOB,
    }),
    initializeJobProperties() {
      const nextInterval =
        Math.ceil(moment().add(1, 'minute').minute() / 15) * 15 // 15, 30, 45, or 60
      const defaultStart = moment().startOf('hour').add(nextInterval, 'minutes')
      const defaultEnd = moment()
        .startOf('hour')
        .add(nextInterval, 'minutes')
        .add(1, 'hour')

      this.jobUuid = uuidv4()
      this.status = DRAFT
      this.approvalStatus = NO_APPROVAL
      this.unitWorkingStatus = TASK_NOT_STARTED
      this.priorityId = priorities.filter((p) => p.name === 'New')[0].id
      this.ownerId = null
      this.customerSid = null
      this.followers = []
      this.activityId = null
      this.inventory = []
      this.crew = []
      this.unitId = null
      this.serviceProviderId = null
      this.serviceProviderBusinessUnitId = null
      this.costCenter = ''
      this.details = ''
      this.destinationLocationId = this.locationId
      this.attachmentsStorageId = null
      this.extensionSchemaId = null
      this.customData = {}
      this.projectId = null
      this.tags = []
      this.created = moment().toISOString()
      this.jobHubId = this.hubId
      this.assignmentGroupNumber = null
      this.invoiceId = null

      if (!this.canModifyRequester) {
        this.autoFill('requestor', this.$auth.user.sub)
      }
      this.autoFill('start', defaultStart)
      this.autoFill('end', defaultEnd)
    },
    async setJobProperties(job) {
      this.jobId = job.id
      this.jobUuid = job.uuid
      this.status = job.status
      this.isArchived = job.isArchived
      this.approvalStatus = job.approvalStatus
      this.unitWorkingStatus = job.unitWorkingStatus
      this.priorityId = job.priorityId
      this.ownerId = job.ownerId
      this.customerSid = job.customerSid
      this.followers = job.followers
      this.startTime = job.startTime
      this.endTime = job.endTime
      this.activityId = job.activityId
      this.inventory = job.inventory
      this.crew = job.crew
      this.unitId = job.unitId
      this.serviceProviderBusinessUnitId = job.serviceProviderBusinessUnitId
      this.poolId = job.poolId
      this.poolExecutionPlan = job.poolExecutionPlan
      this.resourceTypeId = job.resourceTypeId
      this.costCenter = job.costCenter
      this.details = job.details
      this.destinationLocationId = job.destinationLocationId
      this.attachmentsStorageId = job.attachmentsStorageId
      this.extensionSchemaId = job.extensionSchemaId
      this.customData = job.customData
      this.projectId = job.projectId
      this.tags = job.tags
      this.created = job.created
      this.createdBy = job.createdBy
      this.activeDuration = job.actualActiveDuration
      this.formSubmissions = job.formSubmissions
      this.forms = job.associatedForms
      this.attachments = job.attachments
      this.comments = job.comments
      this.jobHubId = job.hubId
      this.assignmentGroupNumber = job.assignmentGroupNumber
      this.assignmentFormRequirements = job.assignmentFormRequirements
      this.invoiceId = job.invoiceId
      this.signature = job.signature
      this.accountId = job.accountId

      this.contextualHubId = job.hubId

      await this.fetchExtensionSchema()
    },
    async fetchExtensionSchema() {
      this.extensionSchemaFetchError = null

      if (this.extensionSchemaId && this.customFieldVersioningEnabled) {
        try {
          this.extensionSchema = await getExtensionSchema(
            this.extensionSchemaId
          )
        } catch (error) {
          // error message rendered in TicketForm.vue
          this.extensionSchemaFetchError = error
        }
      }
    },
    resetCloneJob(job) {
      const versionMismatch =
        this.customFieldVersioningEnabled &&
        this.extensionSchemaId !== this.getLatestTaskSchemaVersion()

      if (versionMismatch) {
        this.setSnackbarData({
          type: 'warn',
          messageKey: 'versionUpdate',
        })
      }

      this.isClonedTicket = true

      if (!job.completedTime) this.$refs['ticket-form'].resetAdHocPool()
      this.setJobProperties(job)

      const timeDiff =
        (new Date(job.endTime) - new Date(job.startTime)) / (1000 * 60)
      const hours = moment(job.startTime).hours()
      const minutes = moment(job.startTime).minutes()
      const current = moment().toISOString()

      this.jobId = null
      this.jobUuid = uuidv4()
      this.isArchived = false
      this.approvalStatus = NO_APPROVAL
      this.unitWorkingStatus = null
      this.priorityId = priorities.find((p) => p.name === 'New').id
      this.created = current
      this.modified = current
      this.serviceProviderBusinessUnitId = null
      this.unitId = null
      this.crew = []
      this.tags = []
      this.attachmentsStorageId = null
      this.attachments = []
      this.projectId = this.$route.params.projectId
      this.assignmentGroupNumber = null
      this.formSubmissions = []
      this.forms = []
      this.comments = []
      this.poolId = null
      this.poolExecutionPlan = null
      this.assignmentFormRequirements = []
      this.extensionSchemaId = null
      this.signature = null
      this.accountId = null

      if (!this.visibleActivities.map((a) => a.id).includes(this.activityId)) {
        this.activityId = null
        this.customData = {}
      } else if (this.customFieldsSchema.uiSchema) {
        this.customData = applyCustomDataOnCloneEffects(
          this.customFieldsSchema.uiSchema['ui:onClone'],
          this.customData
        )
      }

      this.status = DRAFT

      if (!this.canModifyRequester) {
        this.autoFill('requestor', this.$auth.user.sub)
      }

      this.autoFill(
        'start',
        current >= moment(job.startTime)._i
          ? resetTime(hours, minutes, 0)
          : job.startTime
      )
      this.autoFill(
        'end',
        current >= moment(job.startTime)._i
          ? resetTime(hours, minutes, timeDiff)
          : job.endTime
      )
    },
    async saveTicket() {
      if (this.failsFieldValidation(this.status)) {
        this.setSnackbarData({ type: 'error', messageKey: 'validationError' })
        return
      }
      if (this.extensionSchemaFetchError) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'failedToFetchSchema',
        })
        return
      }
      this.prepareCurrentDataForSubmission()

      if (this.isNewDraftTicket) {
        this.isCreatingJobs = true
        await this.handleCreate(this.getStartTimes(), this.getDuration())
      } else {
        await this.handleUpdate()
      }
    },
    failsFieldValidation(jobStatus) {
      return !this.$refs['ticket-form'].validate(jobStatus)
    },
    prepareCurrentDataForSubmission() {
      if (this.isClonedTicket) {
        this.extensionSchemaId = this.getLatestTaskSchemaVersion()
      }
      this.customData = prepareCustomDataForSubmission(
        this.customFieldsSchema,
        this.customData
      )
    },
    async handleCreate(startTimes, duration) {
      if (this.isInvalidBulkCreate()) return

      const response = await this.createJobs({
        ...this.getCreateData(),
        startTimes,
        duration,
      })
      if (this.bulkCreate) {
        // If this is NOT a bulk create, then the job is fetched to Vuex in beforeRouteEnter() above
        await Promise.all(response.job_ids.map((id) => this.fetchJob(id)))
      } else if (!this.ticketsError) {
        // If this is NOT a bulk create AND the job was successfully created, then we upload attachments
        await Promise.all(
          this.attachments.map((attachment) =>
            this.addAttachment({
              jobId: this.jobUuid,
              attachmentId: attachment.id,
              file: attachment.tempFile,
            })
          )
        )
      }
      await this.grantLocationsToHub()

      this.handleCreateResponse(response)
    },
    isInvalidBulkCreate() {
      if (this.bulkCreate && this.timeSlotDates.length === 0) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'bulkCreateNoDateError',
        })
        return true
      } else if (
        this.bulkCreate &&
        this.timeSlotDates.length > this.BULK_JOB_LIMIT
      ) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'bulkCreateLimitError',
        })
        return true
      }
      return false
    },
    getCreateData() {
      return {
        jobUuid: this.bulkCreate ? null : this.jobUuid,
        activityId: this.activityId,
        attachmentsStorageId: this.attachmentsStorageId,
        costCenter: this.costCenter,
        createProject: this.bulkCreate,
        crew: this.crew,
        extensionSchemaId: this.extensionSchemaId,
        customData: this.customData,
        customerSid: this.customerSid,
        destinationLocationId: this.destinationLocationId,
        details: this.details,
        ownerId: this.ownerId,
        followers: this.followers,
        inventory: this.inventory,
        priorityId: this.priorityId,
        projectId: this.$route.params.projectId,
        serviceProviderBusinessUnitId: this.serviceProviderBusinessUnitId,
        tags: this.tags,
        unitId: this.unitId,
        poolExecutionPlan: this.poolExecutionPlan,
        assignmentFormRequirements: this.assignmentFormRequirements,
      }
    },
    getStartTimes() {
      return this.bulkCreate
        ? this.timeSlotDates
            .sort()
            .map((date) => moment(`${date} ${this.timeSlotStart}`))
        : [this.startTime]
    },
    getDuration() {
      return this.bulkCreate
        ? this.timeSlotDuration * 3600
        : moment.duration(this.endTime.diff(this.startTime)).asSeconds()
    },
    handleCreateResponse(response) {
      if (!this.ticketsError) {
        this.onCreateSuccess(response)
      } else {
        this.onCreateFailure()
        this.isCreatingJobs = false
      }
    },
    onCreateSuccess(response) {
      if (this.bulkCreate) {
        this.$router.push({
          name: this.projectSideSheetRoute,
          params: { projectId: response.project_id, isJobSaved: true },
        })
      } else {
        this.$router.push({
          name: this.onSaveRoute,
          params: { id: response.job_ids[0] },
        })

        setTimeout(() => this.setSnackbarData({ messageKey: 'saved' }), 250)
      }
    },
    onCreateFailure() {
      if (this.bulkCreate) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'bulkCreateSaveError',
        })
      } else {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'genericError',
        })
      }
    },
    async grantLocationsToHub() {
      if (this.isHubUser) return
      try {
        await Promise.all(
          this.inventory
            .filter((inv) => inv.pickupLocationId)
            .map((inv) =>
              this.grantLocation({
                locationId: inv.pickupLocationId,
                hubId: this.jobHubId,
              })
            )
        )
        await this.grantLocation({
          locationId: this.destinationLocationId,
          hubId: this.jobHubId,
        })
      } catch (e) {
        setTimeout(
          () =>
            this.setSnackbarData({
              type: 'error',
              messageKey: 'grantLocationsToHubError',
            }),
          250
        )
      }
    },
    async handleUpdate() {
      await this.updateJob(this.getUpdateData())
      await this.grantLocationsToHub()
      this.handleUpdateResponse()
    },
    getUpdateData() {
      if (!this.customFieldVersioningEnabled) {
        this.extensionSchemaId = this.getLatestTaskSchemaVersion()
      }

      return {
        id: this.jobId,
        priorityId: this.priorityId,
        ownerId: this.ownerId,
        customerSid: this.customerSid,
        followers: this.followers,
        startTime: this.startTime,
        endTime: this.endTime,
        activityId: this.activityId,
        inventory: this.inventory,
        crew: this.crew,
        unitId: this.unitId,
        serviceProviderBusinessUnitId: this.serviceProviderBusinessUnitId,
        costCenter: this.costCenter,
        details: this.details,
        destinationLocationId: this.destinationLocationId,
        extensionSchemaId: this.extensionSchemaId,
        customData: this.customData,
        tags: this.tags,
        poolId: this.poolId,
        poolExecutionPlan: this.poolExecutionPlan,
        assignmentFormRequirements: this.assignmentFormRequirements,
      }
    },
    async handleUpdateResponse() {
      if (!this.ticketsError) {
        await this.fetchJob(this.jobId)
        this.setJobProperties(this.getJobById(this.jobId))
        this.$emit('job-saved', { jobNumber: this.jobId })
        setTimeout(() => this.setSnackbarData({ messageKey: 'saved' }), 250)
        await this.setJobHistory()
      } else {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'genericError',
        })
      }
    },
    async handleUpdateReadonlyJob() {
      if (!this.ticketsError) {
        await this.fetchJob(this.jobId)
        this.setJobProperties(this.getJobById(this.jobId))
        this.$emit('job-saved', { jobNumber: this.jobId })
      } else {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'genericError',
        })
      }
    },
    async updateStatus(jobStatus) {
      if (!this.jobId) return
      if (
        jobStatus === COMPLETED &&
        !this.formsWithSubmissionInfo.every((f) => f.isFilledOut)
      ) {
        this.setSnackbarData({
          messageKey: 'ironSightFormsNotAllFilled',
          type: 'error',
        })
        return
      }
      if (this.failsFieldValidation(jobStatus)) {
        return
      }

      await this.updateJobStatus({ id: this.jobId, jobStatus })

      if (!this.ticketsError) {
        setTimeout(
          () => this.setSnackbarData({ messageKey: 'statusUpdated' }),
          250
        )
        this.setJobProperties(this.getJobById(this.jobId))
      }
    },
    async archive() {
      await this.archiveJob({ uuid: this.jobUuid })

      if (!this.ticketsError) {
        setTimeout(() => this.setSnackbarData({ messageKey: 'archived' }), 250)
        this.setJobProperties(this.getJobById(this.jobId))
      }
    },
    async unarchive() {
      await this.unarchiveJob({ uuid: this.jobUuid })

      if (!this.ticketsError) {
        setTimeout(
          () => this.setSnackbarData({ messageKey: 'unarchived' }),
          250
        )
        this.setJobProperties(this.getJobById(this.jobId))
      }
    },
    async cloneTicket() {
      setTimeout(() => {
        this.$router.push({
          name: this.onSaveRoute,
          params: {
            id: 'new',
            hubId: this.jobHubId,
          },
          query: { fromJob: this.jobId },
        })
      }, 350)

      setTimeout(
        () =>
          this.setSnackbarData({
            messageKey: 'clone',
          }),
        250
      )
    },
    dismissClicked() {
      this.$router.push({
        name: this.parentRouteName,
        params: this.parentRouteParams,
      })
    },
    handleDiscard() {
      const job = this.getJobById(this.jobId)
      this.setJobProperties(job)
      this.$refs['ticket-form'].handleDiscard()
      this.$nextTick(() => {
        // these values get modified by reactive watchers triggered by
        // this.setJobProperties(job)
        // so they need to be overwritten again
        this.inventory = job.inventory
        this.costCenter = job.costCenter
      })
    },
    handleUpdateStartTime(newStart) {
      if (this.endTime.isValid()) {
        this.endTime = moment(newStart).add(this.startEndDuration, 'h')
      }
      this.startTime = newStart
    },
    autoFill(field, value) {
      switch (field) {
        case 'division':
          this.ownerId = value
          break
        case 'requestor':
          this.customerSid = value
          break
        case 'resource-type':
          this.resourceTypeId = value
          break
        case 'activity':
          this.activityId = value
          this.extensionSchemaId = this.getLatestTaskSchemaVersion()
          break
        case 'service-provider':
          this.serviceProviderBusinessUnitId = value
          break
        case 'start':
          this.startTime = value
          break
        case 'end':
          this.endTime = value
          break
        case 'cost-center':
          this.costCenter = value
          break
      }
    },
    closeProjectBottomSheet() {
      this.isProjectVisible = false
    },
    closeBatchBottomSheet() {
      this.isBatchVisible = false
    },
    closeFormSubmission() {
      this.selectedFormSubmissionId = null
      this.selectedFormId = null
    },
    closeSchemaBasedForm() {
      this.selectedFormId = null
      this.selectedFormSubmissionId = null
      this.isEditingJobFormSubmission = false
    },
    closeFormRequirementBottomSheet() {
      this.selectedFormRequirement = null
    },
    showFormSubmission(formId, formSubmissionUuid) {
      if (formSubmissionUuid) {
        this.selectedFormSubmissionId = formSubmissionUuid
        this.selectedFormId = formId
        return
      }

      const form = this.formsWithSubmissionInfo.find((f) => f.formId === formId)

      if (form) {
        this.selectedFormSubmissionId = form.formSubmissionUuid
        this.selectedFormId = form.formUuid
      }
    },
    fillFormSubmission(formId) {
      const form = this.formsWithSubmissionInfo.find((f) => f.formId === formId)

      if (form) {
        this.selectedFormId = form.formUuid
      }
    },
    editFormSubmission(formId, formSubmissionId) {
      this.selectedFormId = formId
      this.selectedFormSubmissionId = formSubmissionId
      this.isEditingJobFormSubmission = true
    },
    showFormSelection() {
      this.isFormSelectionVisible = true
    },
    closeNewFormSelection() {
      this.isFormSelectionVisible = false
    },
    selectNewForm({ formId }) {
      this.isFormSelectionVisible = false
      this.selectedFormId = formId
    },
    showFormRequirementBottomSheet(formRequirement) {
      this.selectedFormRequirement = formRequirement
    },
    showAssignmentFormSelection() {
      this.isAssignmentFormSelectionVisible = true
    },
    closeAssignmentFormSelection() {
      this.isAssignmentFormSelectionVisible = false
    },
    showLineItemBottomSheet() {
      this.isLineItemBottomSheetVisible = true
    },
    hideLineItemBottomSheet() {
      this.isLineItemBottomSheetVisible = false
    },
    showNewGuestUserBottomSheet() {
      this.isNewGuestUserBottomSheetVisible = true
    },
    hideNewGuestUserBottomSheet() {
      this.isNewGuestUserBottomSheetVisible = false
    },
    addAssignmentFormRequirement(requirement) {
      this.isAssignmentFormSelectionVisible = false
      this.assignmentFormRequirements = [
        ...this.assignmentFormRequirements,
        JobAssignmentFormRequirement.fromFormId(requirement.formId),
      ]
    },
    getLatestTaskSchemaVersion() {
      const activity = this.getActivityById(this.activityId)

      return this.getTaskById(activity.taskId).version
    },
    async handleActionBarClick(data) {
      switch (data.type) {
        case actionTypes.SEND_NOTIFICATION:
          return (this.isEmailDialogVisible = data.value)
        case actionTypes.FOCUS_RESOURCE:
          this.$refs['ticket-form'].$refs[
            'task-item'
          ].$refs.resourceAssignment.focus()
          break
        case actionTypes.CLONE:
          return this.cloneTicket()
        case actionTypes.STATUS_CHANGE:
          await this.updateStatus(data.value)
          break
        case actionTypes.ADD_TO_PROJECT:
          this.isProjectVisible = true
          break
        case actionTypes.REMOVE_FROM_PROJECT:
          await this.removeJobFromProject()
          break
        case actionTypes.VIEW_PROJECT:
          this.pushToProject(this.projectId)
          break
        case actionTypes.ADD_TO_BATCH:
          this.isBatchVisible = true
          break
        case actionTypes.REMOVE_FROM_BATCH:
          await this.removeJobFromBatch()
          break
        case actionTypes.VIEW_BATCH:
          this.pushToBatch(this.invoiceId)
          break
        case actionTypes.DOWNLOAD_PDF:
          if (this.pdfFormats.length === 1) {
            await this.downloadPdf(this.pdfFormats[0].id)
          } else {
            this.showingPdfMenu = true
          }
          break
        case actionTypes.ARCHIVE:
          await this.archive({
            id: this.jobId,
          })
          break
        case actionTypes.UNARCHIVE:
          await this.unarchive({
            id: this.jobId,
          })
          break
        default:
          break
      }
    },
    unsupportedFileSelected(messageKey) {
      this.setSnackbarData({ type: 'error', messageKey })
    },
    errorUploadingAttachment(messageKey) {
      this.setSnackbarData({ type: 'error', messageKey })
    },
    async addToBatch(batchId) {
      this.invoiceId = batchId
      this.closeBatchBottomSheet()
      this.pushToBatch(batchId)
      this.$emit('job-saved', { jobNumber: this.jobId })
      await this.fetchJob(this.jobId)
    },
    async removeJobFromBatch() {
      try {
        this.savingJob()
        await removeJobsFromInvoice(this.invoiceId, [this.jobUuid])
        await this.fetchJob(this.jobId)
        this.invoiceId = null
        this.setSnackbarData({
          type: 'info',
          messageKey: 'removeJobFromBatchSuccess',
        })
        this.$emit('job-saved', { jobNumber: this.jobId })
        this.savedJob()
      } catch (e) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'removeJobFromBatchError',
        })
        this.errorSavingJob()
      }
    },
    async addToProject(projectId) {
      this.projectId = projectId

      this.closeProjectBottomSheet()
      this.pushToProject(this.projectId)

      this.$emit('job-saved', { jobNumber: this.jobId })
    },
    async removeJobFromProject() {
      await this.removeJob({
        projectId: this.projectId,
        jobId: this.jobId,
      })

      if (this.removeJobError) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'removeJobFromProjectError',
        })
      } else {
        this.$emit('job-saved', { jobNumber: this.jobId })
      }
    },
    pushToProject(projectId) {
      this.$router.push({
        name: this.projectSideSheetRoute,
        params: { projectId },
      })
    },
    pushToBatch(batchId) {
      this.$router.push({
        name: this.batchSideSheetRoute,
        params: { batchId, parentRouteName: this.parentRouteName },
      })
    },
    handleNotificationSent() {
      this.setSnackbarData({ messageKey: 'notificationSent' })
    },
    handleJobAddedFailure(messageKey) {
      this.setSnackbarData({
        type: 'error',
        messageKey,
      })
      this.closeProjectBottomSheet()
    },
    setSnackbarData({ type = 'info', messageKey }) {
      Object.assign(this.snackbar, { type, messageKey })
      this.isSnackbarVisible = true
    },
    setEmailDialogVisibility(isVisible) {
      this.isEmailDialogVisible = isVisible
    },
    async attachFormSubmission(formSubmissionId) {
      await this.attachFormSubmissionId({
        jobId: this.jobUuid,
        formId: this.selectedFormId,
        formSubmissionId,
      })
      if (!this.ticketsError) {
        this.setJobProperties(this.getJobById(this.jobId))
        this.setSnackbarData({ messageKey: 'attachedForm' })
      } else {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'attachedFormError',
        })
      }
    },
    updatedAttachedFormSubmission() {
      this.closeFormSubmission()
      this.setSnackbarData({
        messageKey: 'updatedAttachedFormSubmission',
      })
    },
    async attachFormRequirement(formSubmissionId) {
      await this.attachFormRequirementSubmission({
        jobId: this.jobUuid,
        formRequirementId: this.selectedFormRequirement.id,
        formSubmissionId,
      })
      if (!this.ticketsError) {
        this.setJobProperties(this.getJobById(this.jobId))
        this.setSnackbarData({ messageKey: 'attachFormRequirement' })
      } else {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'attachFormRequirementError',
        })
      }
    },
    updatedFormRequirementSubmission() {
      this.closeFormRequirementBottomSheet()
      this.setSnackbarData({
        messageKey: 'updatedFormRequirementSubmission',
      })
    },
    async downloadPdf(pdfFormatId) {
      this.isPdfDownloading = true

      try {
        saveFile(await getJobPdf(this.jobUuid, pdfFormatId))
      } catch {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'pdfDownloadError',
        })
      }

      this.isPdfDownloading = false
    },
    async setJobHistory() {
      try {
        const { data } = await getJobHistory(this.jobId)
        this.history = data.map((history) => new JobHistory(history))
      } catch (error) {
        this.loadHistoryError = true
      }
    },
    async fetchProject(projectId) {
      await this.fetchMissingProjectsByIds([projectId])
    },
    async createComment(comment) {
      await this.createJobComment({ jobId: this.jobUuid, comment })
      this.setJobProperties(this.getJobById(this.jobId))
    },
    async removeJobFromGroup() {
      await this.removeJobFromAssignmentGroup(this.jobId)
      if (!this.ticketsError) {
        this.setJobProperties(this.getJobById(this.jobId))
        this.setSnackbarData({ messageKey: 'removedJobFromGroup' })
      } else {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'removeJobFromGroupError',
        })
      }
    },
    handleJobClaimed() {
      this.$router.push({ query: { claimed: Date.now() } })
    },
    updatePoolExecutionPlan(value) {
      this.poolExecutionPlan = value
    },
    updateAssignmentFormRequirements(requirements) {
      this.assignmentFormRequirements = requirements
    },
    clearAssignedPool() {
      this.poolId = null
    },
    async handlePoolClosed() {
      if (!this.draftChangesDetected) {
        await this.fetchJob(this.jobId)
        this.setJobProperties(this.getJobById(this.jobId))
      }
    },
    showBottomSheet() {
      this.isPortalBottomSheetVisible = true
    },
    hideBottomSheet() {
      this.isPortalBottomSheetVisible = false
    },
  },
}
</script>

<style scoped>
.description {
  margin-top: calc(-1 * var(--space-base));
  margin-bottom: var(--space-small);
}
</style>
