<template>
  <form-layout title="Edit Locations/Inventory" @on-dismiss="dismiss">
    <vs-wrapper>
      <job-locations-and-inventory-edit
        ref="inventory-edit-component"
        :resource-type-id="job.resourceTypeId"
        :activity-id="job.activityId"
        :destination-location-id="destinationLocationId"
        :inventories="inventories"
        :job-hub-id="jobHubId"
        :is-pickup-required="true"
        @update-inventories="inventories = $event"
        @update-destination="destinationLocationId = $event"
      />

      <v-spacer />

      <v-row no-gutters>
        <vs-button
          label="Save"
          full-width
          :loading="saving"
          :disabled="!hasChanges"
          @click="save"
        />
      </v-row>
    </vs-wrapper>

    <template #snack-bar-wrapper>
      <vs-snackbar v-model="isSnackbarVisible" type="error" sticky>
        Failed to update job locations/inventory
      </vs-snackbar>
    </template>
  </form-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Job, COMPLETED } from '../../models/job'
import FormLayout from '../common/FormLayout.vue'
import JobLocationsAndInventoryEdit from './JobLocationsAndInventoryEdit.vue'
import { inventoriesAreEqual } from '@/utils/inventories'

export default {
  components: {
    FormLayout,
    JobLocationsAndInventoryEdit,
  },

  props: {
    job: {
      type: Job,
      required: true,
    },
    jobHubId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      saving: false,
      isSnackbarVisible: false,
      inventories: [],
      destinationLocationId: null,
    }
  },

  mounted() {
    this.destinationLocationId = this.job.destinationLocationId
    this.inventories = this.job.inventory
  },

  computed: {
    ...mapState('tickets', {
      ticketsError: 'error',
    }),
    hasChanges() {
      return (
        this.destinationLocationId !== this.job.destinationLocationId ||
        !inventoriesAreEqual(this.inventories, this.job.inventory)
      )
    },
  },

  methods: {
    ...mapActions('jobs', ['updateJob']),
    async save() {
      if (!this.$refs['inventory-edit-component'].validate(COMPLETED)) return

      const updatedJob = Object.assign({}, this.job, {
        // This is to solve that customData can't be copied properly
        customData: this.job.customData,
        destinationLocationId: this.destinationLocationId,
        inventory: this.inventories,
      })

      this.saving = true
      await this.updateJob(updatedJob)
      this.saving = false

      if (this.ticketsError) {
        this.isSnackbarVisible = true
      } else {
        this.$emit('updated')
        this.dismiss()
      }
    },
    dismiss() {
      this.$emit('dismiss')
    },
  },
}
</script>
