export function addOrdinalIndicator(input: number): string {
  // Handle exceptions for 11, 12, and 13
  if (input % 100 === 11 || input % 100 === 12 || input % 100 === 13) {
    return input + 'th'
  }

  switch (input % 10) {
    case 1:
      return input + 'st'
    case 2:
      return input + 'nd'
    case 3:
      return input + 'rd'
    default:
      return input + 'th'
  }
}

export function useCurrencyFormatter() {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}
