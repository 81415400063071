import 'intersection-observer'
import '@mdi/font/css/materialdesignicons.css'
import '@mdi/font/fonts/materialdesignicons-webfont.eot'

import '@mdi/font/fonts/materialdesignicons-webfont.ttf'
import '@mdi/font/fonts/materialdesignicons-webfont.woff'
import '@mdi/font/fonts/materialdesignicons-webfont.woff2'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import { camelCase, upperFirst } from 'lodash'
import LogRocket from 'logrocket'
import moment from 'moment'
import PortalVue from 'portal-vue'
import vClickOutside from 'v-click-outside'
import 'vis-timeline/styles/vis-timeline-graph2d.css'
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueZoomer from 'vue-zoomer'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { ironsightAxios } from './api/base'
import App from './App.vue'
import { Auth0Plugin } from './auth'
import VueApplicationInsights from './telemetry/VueApplicationInsights'
import router from './router.js'
import store from './store'
import { VueQueryPlugin, VueQueryPluginOptions } from '@tanstack/vue-query'
import vuetify from './plugins/vuetify'
import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import 'mapbox-gl/dist/mapbox-gl.css'

moment.relativeTimeThreshold('ss', 5)

export const SET_CLIENT_VERSION = 'SET_CLIENT_VERSION'

const clientVersion = process.env.VUE_APP_RELEASE
Vue.prototype.$clientVersion = clientVersion

ironsightAxios.interceptors.response.use((response) => {
  if ('x-client-version' in response.headers) {
    store.commit(SET_CLIENT_VERSION, response.headers['x-client-version'])
  }

  return response
})

const requireComponent = require.context(
  // The relative path of the components folder
  './components/vision',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /Vs[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()!
        .replace(/\.\w+$/, '')
    )
  )

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

const isProduction = process.env.NODE_ENV === 'production'
const logRocketAppId = process.env.VUE_APP_LOGROCKET_PROJECT

if (isProduction && logRocketAppId) {
  LogRocket.init(logRocketAppId, {
    release: clientVersion,
  })
} else if (!logRocketAppId) {
  console.debug('🚫🚀 Skipped LogRocket initialization due to missing app ID.')
}

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
}

Vue.use(VueRouter)
Vue.use(vClickOutside)
Vue.use(PortalVue)
Vue.use(VueZoomer)
Vue.use(VueVirtualScroller)
Vue.use(VueQueryPlugin, vueQueryPluginOptions)
Vue.use(PiniaVuePlugin)

Vue.use(Auth0Plugin, {
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  },
})

Vue.config.productionTip = false
Vue.config.performance = !isProduction

if (isProduction) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: clientVersion,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })

  Vue.use(VueApplicationInsights, {
    router,
  })
}

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

new Vue({
  render: (h) => h(App),
  router,
  store,
  vuetify,
  pinia,
}).$mount('#app')
