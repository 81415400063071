var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.job)?_c(_setup.VsCard,{staticClass:"project-card-wrapper",attrs:{"to":{
    name: _setup.jobSideSheetUrl,
    params: { id: _vm.job.number },
  }}},[_c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c(_setup.VsText,[_vm._v(" Job #"+_vm._s(_vm.job.number)+" · "+_vm._s(_setup.company?.shortCode)+" ")])],1),_c('v-col',{staticClass:"shrink"},[_c('v-icon',{attrs:{"small":"","color":_setup.priority?.color}},[_vm._v(" "+_vm._s(_setup.priority?.icon)+" ")])],1),_c('v-col',{staticClass:"shrink"},[_c(_setup.JobStatusChip,{attrs:{"status":_vm.job.status}})],1),_c('v-col',{class:[
          'shrink',
          'project-checkbox',
          { 'is-visible': _vm.showCheckbox },
        ]},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":""},on:{"click":function($event){$event.preventDefault();}},model:{value:(_setup.selected),callback:function ($$v) {_setup.selected=$$v},expression:"selected"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c(_setup.VsHeading,[_vm._v(_vm._s(_setup.task.name))])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c(_setup.VsText,{attrs:{"type":"caption"}},[_vm._v(_vm._s(_setup.taskText))])],1)],1)],1),(_vm.job.details)?_c(_setup.VsText,[_vm._v(_vm._s(_vm.job.details))]):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"shrink pr-4"},[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v("calendar_today")])],1),_c('v-col',[_c(_setup.VsText,[_vm._v(_vm._s(_setup.scheduleTime))])],1)],1),_c(_setup.JobCardLocationsDetail,{attrs:{"inventory":_setup.inventory,"destination-location-name":_vm.job.destination_name}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }