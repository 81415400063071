<template>
  <router-link
    v-ripple
    class="unit-link"
    :to="{ name: unitSideSheetUrl, params: { id: unitNumberId } }"
  >
    <UnitCard :id="unitNumberId" class="unit-card" />
  </router-link>
</template>

<script>
import UnitCard from '@/components/units/UnitCard'

export default {
  name: 'UnitGroup',
  components: {
    UnitCard,
  },
  props: {
    unitNumberId: {
      type: Number,
      required: true,
    },
    unitSideSheetUrl: {
      type: String,
      required: true,
      default: 'sv-unit-overview',
    },
  },
}
</script>

<style scoped>
.unit-link {
  display: block;
  user-select: none;
  text-decoration: none;
  color: inherit;
  padding: var(--space-base) var(--space-small);
}

.unit-link:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.unit-card {
  max-width: 260px;
}
</style>
